import React from 'react'

import { FILTER_STATES, SALES_DETAILS_TEXTS, SALES_MAIN_TITLE } from '../../sales/constants'
import FormattedDate from '../../../common/components/formattedDate'
import FormattedTime from '../../../common/components/formattedTime'
import { getAuthorizationOperation } from '../../sales/helpers'
import MonetaryAmount from '../../../common/components/monetaryAmount'
import { Link } from 'react-router-dom'
import { TRANSACTION_TYPE_REFUND } from '../constants'


const originalLink = (authorization, merchant, formatMessage) => {
    let cardNumber = authorization.card_number
    if (authorization.original_transaction_id) {
        return (
            <Link to={`/merchants/${merchant.id}/sales/instore/${authorization.original_transaction_id}`} key={authorization.original_transaction_id}>
                {authorization.original_transaction_id}
            </Link>
        )
    } else {
        return (
            <Link to={{ pathname: `/merchants/${merchant.id}/sales`, state: { query: `?search=${cardNumber}`}}}>
                {formatMessage(SALES_MAIN_TITLE['acquiring'])}
            </Link>
        )
    }

}

const SalesInstorePaymentDetails = ({merchant, payment, formatMessage}) => {
    const authorization = getAuthorizationOperation(payment)
    const isRefund = TRANSACTION_TYPE_REFUND.includes(payment.transaction_type_id)
    return (
        <div className="payment-details">
            <div className="row header">{formatMessage(SALES_DETAILS_TEXTS['payment_acquiring_transaction'])}</div>
            <div className="detail-wrapper">
            <div className="row list">
                    <div>{formatMessage(SALES_DETAILS_TEXTS['payment_acquiring_date'])}</div>
                        { authorization.transaction_datetime ?
                            <div className="element">
                                <FormattedDate elementClass="date" date={authorization.transaction_datetime} />
                                <FormattedTime date={authorization.transaction_datetime} />
                            </div> : <div className="element">-</div>}
                    </div>
                    <div className="row list">
                        <div>{formatMessage(SALES_DETAILS_TEXTS['payment_acquiring_type'])}</div>
                        <div className="element">{formatMessage(FILTER_STATES[isRefund ? 'refund' : 'sale'])}</div>
                    </div>
                    <div className="row list">
                        <div>{formatMessage(SALES_DETAILS_TEXTS['payment_acquiring_amount'])}</div>
                        <div className="element">
                            <MonetaryAmount amount={authorization.amount}
                                            amount_exponent={authorization.currency_exponent}
                                            currency={authorization.currency_code}
                                            convertAmountSign={isRefund}
                                            className="fg-darkgreen"
                                            hideInsignificantDecimals={false} />
                        </div>
                    </div>
                    { isRefund ? <React.Fragment>
                        <div className="row list">
                            <div>{formatMessage(SALES_DETAILS_TEXTS['reason'])}</div>
                            <div className="element">{authorization.message}</div>
                        </div>
                        <div className="row list">
                            <div>{formatMessage(SALES_DETAILS_TEXTS['original_transaction_reference'])}</div>
                            <div className="element otr">
                            {originalLink(authorization, merchant, formatMessage) }
                            </div>
                        </div>
                    </React.Fragment> :
                        <div className="row list">
                            <div>{formatMessage(SALES_DETAILS_TEXTS['payment_instore_tip_amount'])}</div>
                            <div className="element">
                                <MonetaryAmount amount={authorization.tip_amount} amount_exponent={authorization.currency_exponent} currency={authorization.currency_code} className="fg-darkgreen" hideInsignificantDecimals={false} />
                            </div>
                        </div>
                    }
                    <div className="row list">
                        <div>{formatMessage(SALES_DETAILS_TEXTS['payment_instore_terminal_reference'])}</div>
                        <div className="element">{authorization.terminal_retrieval_reference_number}</div>
                    </div>
                    <div className="row list">
                        <div>{formatMessage(SALES_DETAILS_TEXTS['payment_instore_transaction_reference'])}</div>
                        <div className="element">{payment.payment_id}</div>
                    </div>
            </div>
        </div>
    )
}

export default SalesInstorePaymentDetails
