import React, { useState } from 'react'
import { injectIntl } from 'react-intl'
import { SUPPORTED } from '../translations'

import isBrowserSupported from '../helpers/isBrowserSupported'
import isDeviceSupported from '../helpers/isDeviceSupported'
import Modal from 'react-bootstrap/Modal'
import './supportModal.scss'

const getMsg = (intl) => {
    if (!isDeviceSupported()) return intl.formatMessage(SUPPORTED['device'])
    else if (!isBrowserSupported()) return intl.formatMessage(SUPPORTED['unsupported_browser'], {
        browsers: 'Chrome, Firefox, Safari',
        browser: 'Microsoft Edge'
    })
    else return null
}

const SupportModal = ({ intl }) => {
    const [show, setShow] = useState(true)
    const msg = getMsg(intl)

    return (
        <Modal show={msg && show} onHide={() => setShow(false)} className="support-modal" backdrop="static">
            <Modal.Body>
                <span className="supportal-text">
                    <p>{msg}</p>
                </span>
                <button onClick={() => setShow(false)} className="btn bg-danger">{intl.formatMessage(SUPPORTED['btn'])}</button>
            </Modal.Body>
        </Modal>
    )
}


export default injectIntl(SupportModal)
