import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, Link, Redirect } from 'react-router-dom'
import mixpanel from 'mixpanel-browser'

import SalesDetailsContainer from './sales/sales/containers/salesDetailsContainer'
import SettlementsListContainer from './settlements/containers/settlementsListContainer'
import SettlementTransactionByEmidContainer from './settlements/containers/settlementTransactionByEmidContainer'
import MoreSettlementDetailsContainer from './settlements/containers/moreSettlementDetailsContainer'
import { Settings } from './settings/settings'
import Dashboard from './dashboard/containers/dashboardContainer'
import NotFoundState from './common/components/notFoundState'
import { FormattedMessage } from 'react-intl'
import InvoiceContainer from './invoice/containers/invoiceContainer'
import LoanContainer from './loans/containers/loanContainer'
import SalesContainer from './sales/sales/containers/salesContainer'
import Spinner from './common/components/spinner'
import { loadMerchantData, notPortalEligible } from './merchant/actions'
import { initChat } from './integrations/salesforce_chat'
import './merchantRoutes.scss'
import LoanComponent from '@frontrunners/loan-ui-component'
import LoanErrorNotification from './loans/components/loanErrorNotification'
import { SUPPORTAL_ERROR_MESSAGE } from './loans/constants'
import MonthlyReportContainerWrapper from './monthly_reports/monthlyReportsContainer'
import { DataAvailableFromDate } from './common/dateHelpers'
import { DeveloperModeContext } from './common/helpers/featureToggle'
import { CustomerFeedbackWindow } from './components/CustomerFeedbackWindow/customerFeedbackWindow'
import { dateIsBetweenDates } from './common/dateHelpers'


const Invalid = ({ user }) => {
    const merchantIds = user.get('merchants')
    return <NotFoundState>
        <h2 className="text-center">
            <FormattedMessage
                id="merchant-not-found-title"
                defaultMessage={'Sorry, we can\'t find this account'}
                description="Failed getting merchant title."
            />
        </h2>
        <p className="text-center">
            <FormattedMessage
                id="merchant-not-found-text"
                defaultMessage={'If this keeps happening contact our support staff at support.nordics@worldline.com.'}
                description="Failed getting merchant data text."
                values={{
                    merchantId: this.props.match.params.merchantId
                }}
            />
        </p>
        <p className="text-center">
            <Link to="/" >
                {merchantIds.size > 1 &&
                    <FormattedMessage
                        id="merchant-not-found-link-multiple"
                        defaultMessage={'Go back to account selection'}
                        description="Failed getting account data link back to merchant selection."
                    />
                }
                {merchantIds.size === 1 &&
                    <FormattedMessage
                        id="merchant-not-found-link-single"
                        defaultMessage={'Go back to {merchantName} '}
                        description="Failed getting account data link back to merchant selection."
                        values={{
                            merchantName: this.props.merchant.get('merchants')[0].name
                        }}
                    />
                }
            </Link>
            {
                merchantIds.size === 0 &&
                <FormattedMessage
                    id="merchant-not-found-empty-merchant-list"
                    defaultMessage={'Your user have no merchats in the user object.'}
                    description="Failed getting merchant data no merchants message."
                />
            }
        </p>
    </NotFoundState>
}

const ValidateMerchantId = ({ match, user }) => {
    const merchantId = match.params.merchantId
    const merchantIds = user.get('merchants')
    return merchantIds.includes(merchantId)
}

const trackChatMixpanel = () => {
    //check when the button in the dom, add the click event of mixpanel tracking
    let counter = 0
    const btnEvent = setInterval(() => {
        counter++
        const buttons = document.getElementsByClassName('embeddedServiceHelpButton')
        if (buttons && buttons.length > 0) {
            clearInterval(btnEvent)
        }
        else {
            if (counter > 20) clearInterval(btnEvent)
        }
    }, 1000)
}

const MerchantRoutes = ({ match, user, merchant, localization, config, dispatch }) => {
    const { developerMode, featureToggle } = useContext(DeveloperModeContext)
    // dont render merchant body for invalid route
    // since the merchant should be redirected to a valid merchant id
    const isValid = <ValidateMerchantId user={user} match={match} />
    if (!isValid) return <Invalid user={user} />
    const merchantId = match.params.merchantId

    const locale = localization.get('localeCode')
    const [importChat, setImportChat] = useState(false)
    const [chatLocale, setChatLocale] = useState(null)
    const currentMerchant = merchant &&
        merchant.get('merchants') &&
        merchant.get('merchants')
            .find(item => item.id === merchantId)

    if (currentMerchant && notPortalEligible(currentMerchant, user)) {
        return <div className="merchant-not-portal-eligible">
            <FormattedMessage
                id="merchant-not-portal-eligible"
                defaultMessage={'The Merchant Portal has been disabled for this merchant.'}
                description="The merchant is too large"
            />
            <br />
            <br />
            <FormattedMessage
                id="merchant-not-portal-eligible-details"
                defaultMessage={'There are too many transactions to generate reports and provide a genuine portal experience.'}
                description="The merchant is too large detail text"
            />
        </div>
    }

    useEffect(() => {
        if (currentMerchant) {
            const chat_eligible = currentMerchant.extra_data && currentMerchant.extra_data.chat_eligible

            if (!importChat && chat_eligible && chatLocale !== locale) {
                initChat(locale)
                setImportChat(true)
                setChatLocale(locale)
                trackChatMixpanel()
            }
        }
    }, [currentMerchant])

    useEffect(() => {
        dispatch(loadMerchantData(merchantId))
    }, [merchantId])

    const LoanComponentWrapper = () => {
        const apiConfig = {
            API_HOST: config.get('apiHost'),
            FRODA_API_HOST: config.get('frodaApiHost'),
            FRODA_HOME_HOST: config.get('frodaHost')
        }

        const locale = localization.get('localeCode')

        if (currentMerchant && currentMerchant.extra_data && currentMerchant.extra_data.loan_consent) {
            const country = currentMerchant.billing_address && currentMerchant.billing_address.country
            return (
                <div className="loan-component-wrapper" style={{ marginTop: '75px', marginLeft: '25px' }}>
                    <LoanComponent
                        config={apiConfig}
                        merchantId={merchantId}
                        locale={locale}
                        mixpanel={mixpanel}
                        country={country}
                        isSupportAgent={currentMerchant?.loggedInUserIsSupport}
                    />
                </div>
            )
        }

        return (<LoanErrorNotification message={SUPPORTAL_ERROR_MESSAGE} />)
    }

    // To limit all data in the portal, we receive the latest date from the merchant object.
    // This date is set to a singleton object to use at lower level, i.e in actions and filters
    DataAvailableFromDate.instance.setDate(currentMerchant && currentMerchant.extra_data && currentMerchant.extra_data.data_available_from_date)

    const showCustomerFeedbackWindow = dateIsBetweenDates(new Date(), new Date('2023-07-03 00:00:00'), new Date('2023-07-05 00:00:00'))
    const preferredLoanComponent = developerMode && featureToggle.frodaLoanFrame ? LoanContainer : LoanComponentWrapper

    if (merchant.get('hasFetchedMerchantDetails')) {
        return <>
            {showCustomerFeedbackWindow && <CustomerFeedbackWindow />}
            <Switch>
                <Route exact={true} path="/merchants/:merchantId/payouts" component={SettlementsListContainer} />
                <Route exact={true} path="/merchants/:merchantId" component={Dashboard} />
                <Route exact={true} path="/merchants/:merchantId/sales" component={SalesContainer} />
                <Route exact={true} path="/merchants/:merchantId/sales/acquiring/:id" component={SalesDetailsContainer} />
                <Route exact={true} path="/merchants/:merchantId/sales/online/:paymentId" component={SalesDetailsContainer} />
                <Route exact={true} path="/merchants/:merchantId/sales/instore/:paymentId" component={SalesDetailsContainer} />
                <Route exact={true} path="/merchants/:merchantId/payouts/:settlementId" component={MoreSettlementDetailsContainer} />
                <Route exact={true} path="/merchants/:merchantId/payouts/:settlementId/:emid/transactions" component={SettlementTransactionByEmidContainer} />
                <Route exact={true} path="/merchants/:merchantId/reports" component={MonthlyReportContainerWrapper} />
                <Route exact={true} path="/merchants/:merchantId/invoices" component={InvoiceContainer} />
                <Route exact={true} path="/merchants/:merchantId/loans" component={preferredLoanComponent} />
                <Route path="/merchants/:merchantId/settings" component={Settings} />
                <Redirect to={`/merchants/${merchantId}`} />
            </Switch>
        </>
    } else {
        return <div className="text-center">
            <Spinner waiting={true} />
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        config: state.get('config'),
        merchant: state.get('merchant'),
        user: state.get('user').get('profile'),
        localization: state.get('localization')
    }
}

export default connect(mapStateToProps)(MerchantRoutes)
