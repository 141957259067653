import React from 'react'
import uuid from 'uuid'

import { SALES_DETAILS_TEXTS } from '../../sales/constants'
import BamboraSvgIcon from  '../../../common/components/bamboraSvgIcon'
import { OPERATIONS, TRANSACTION_TYPE_REFUND } from '../constants'
import MonetaryAmount from '../../../common/components/monetaryAmount'
import FormattedDate from '../../../common/components/formattedDate'
import FormattedTime from '../../../common/components/formattedTime'


const InstoreEventHistory = ({payment, authorization, localOperations, formatMessage, paymentSettledInformation, redirectLink}) => {
    let originalOperations = payment['operations']
    let _localOperations = Array.from(localOperations)
    const allOperations = [].concat(originalOperations, _localOperations)
    const isRefund = TRANSACTION_TYPE_REFUND.includes(payment.transaction_type_id)

    const getStatusIcon = (operation) => {
        if(!operation.success) return <BamboraSvgIcon icon="history-voided"/>
        var operationType = operation.operation_type.toLowerCase()
        return <BamboraSvgIcon icon={`history-${operationType}`}/>
    }

    const getOperationDate = (operation) => {
        switch(operation.operation_type) {
            case OPERATIONS.AUTHORIZED:
                return <FormattedDate date={operation.transaction_datetime} />
            case OPERATIONS.REFUNDED:
                return <FormattedDate date={operation.processing_datetime} />
            default:
                var operationType = operation.operation_type.toLowerCase()
                return <FormattedDate date={operation[`${operationType}_date_time`]}/>
        }
    }

    const getOperationTime = (operation) => {
        switch(operation.operation_type) {
            case OPERATIONS.AUTHORIZED:
                return <FormattedTime date={operation.transaction_datetime} />
            case OPERATIONS.REFUNDED:
                return <FormattedTime date={operation.processing_datetime} />
            default:
                var operationType = operation.operation_type.toLowerCase()
                return <FormattedTime date={operation[`${operationType}_date_time`]}/>

        }
    }
    const getSplitOperation = (operation) => {
        return ( authorization.direct_capture && operation.operation_type === OPERATIONS.AUTHORIZED &&
            <div className="operation">
                <div className="history">
                    <div className="icon">
                        <BamboraSvgIcon icon="history-captured"/>
                    </div>
                    <MonetaryAmountWrapper authorization={authorization} operation={operation} isRefund={isRefund}/>
                    <div className="date">
                        {getOperationDate(operation)}
                    </div>
                </div>
                <div className="details">
                    <div className="type">
                        {formatMessage(SALES_DETAILS_TEXTS['payment_instore_operation_captured'])}
                    </div>
                    <div className="time">
                            {getOperationTime(operation)}
                    </div>
                    <div className={`operation-status ${operation.success ? 'success' : 'rejected'}`} >
                        {operation.success ? formatMessage(SALES_DETAILS_TEXTS['payment_success']) : formatMessage(SALES_DETAILS_TEXTS['payment_rejected']) }
                    </div>
                </div>
            </div>
        )
    }

    const getSettledInformation = (operation) => {
        if (!paymentSettledInformation) return null

        const settlementDate = paymentSettledInformation.settlement_date

        return ( operation.operation_type === OPERATIONS.CLEARED &&
            <div className="operation">
                <div className="history">
                    <div className="icon">
                        <BamboraSvgIcon icon="sent"/>
                    </div>
                    <MonetaryAmountWrapper authorization={authorization} operation={operation}/>
                    { settlementDate && !isRefund ?
                        <div className="date link" onClick={() => redirectLink(settlementDate)}>
                            { <FormattedDate date={settlementDate}/>}
                        </div> : '-'
                    }
                </div>
                <div className="details">
                    <div className="type">
                        {formatMessage(SALES_DETAILS_TEXTS['payment_instore_operation_settled'])}
                    </div>
                    <div className="time"></div>
                    <div className={`operation-status ${operation.success ? 'success' : 'rejected'}`} >
                        {operation.success ? formatMessage(SALES_DETAILS_TEXTS['payment_success']) : formatMessage(SALES_DETAILS_TEXTS['payment_rejected']) }
                    </div>
                </div>
            </div>
        )
    }

    const Operation = (operation) => {
        return (
            <div className="operation-wrapper" key={uuid()}>
                <div className="operation">
                    <div className="history">
                        <div className="icon">{getStatusIcon(operation, payment)}</div>
                        <MonetaryAmountWrapper authorization={authorization} operation={operation} isRefund={isRefund} />
                        <div className="date">
                            {getOperationDate(operation)}
                        </div>
                    </div>
                    <div className="details">
                        <div className="type">
                            {formatMessage(SALES_DETAILS_TEXTS[`payment_instore_operation_${operation.operation_type.toLowerCase()}`])}
                        </div>
                        <div className="time">
                            {getOperationTime(operation)}
                        </div>
                        <div className={`operation-status ${operation.success ? 'success' : 'rejected'}`} >
                            {operation.success ? formatMessage(SALES_DETAILS_TEXTS['payment_success']) : formatMessage(SALES_DETAILS_TEXTS['payment_rejected']) }
                        </div>
                    </div>
                </div>
                { getSplitOperation(operation)}
                { !isRefund && getSettledInformation(operation) }
            </div>
        )
    }

    return (
        <div className="order-event-history col-2">
            <div className="header">
                <div className="history-header">{formatMessage(SALES_DETAILS_TEXTS['payment_detail_history_header'])}</div>
            </div>
            <div className="operations" data-testid="operation-wrapper">
                {
                    allOperations.map((operation) => <Operation key={uuid.v4()} {...operation}/>)
                }
            </div>
        </div>
    )
}


const MonetaryAmountWrapper = ({authorization, operation, isRefund}) => {
    switch(operation.operation_type) {
        case OPERATIONS.REFUNDED:
            return <MonetaryAmount amount={operation.amount} convertAmountSign={isRefund} amount_exponent={operation.currency_exponent} className="fg-darkest amount" currency={operation.currency_code} excludeCurrency={false} hideInsignificantDecimals={false} />
        default:
            return <MonetaryAmount amount={authorization.amount} convertAmountSign={isRefund}  amount_exponent={authorization.currency_exponent} className="fg-darkest amount" currency={authorization.currency_code} excludeCurrency={false} hideInsignificantDecimals={false} />
    }
}

export default InstoreEventHistory
