import React, { useEffect, useState, useRef, useContext } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { getMerchant } from '../reports/helpers'

import './monthlyReportsContainer.scss'
import { REPORT_EMPTY } from '../reports/constants'
import { useIntl } from 'react-intl'
import * as API from './api'
import * as dateHelpers from '../common/dateHelpers'
import { POLL_STATES, STATES as REPORT_TASK_STATES } from './constants'
import { NAVLINK_TEXTS } from '../nav/constants'
import { TaskProgressBar } from './monthlyReportsProgressBar'
import EmptyState from '../common/components/emptyState'
import { TRIAL_STATES } from '../merchant/constants'
import { trackUserActionEvent } from '../common/helpers/mixpanelTrackers'
import MonthList from './monthList'
import ReportTypeSelector from './monthlyReportsSelector'
import MonthlyReportDescription from './monthlyReportsDescription'
import MonthlyReportsNotificationsContainer from './monthlyReportsNotificationContainer'
import { getAvailableReportTypes } from './reportTypes'
import { downloadFileFromLink } from '../helpers/requestHelper'
import { ApplicationContext } from '../common/reactContext'
import { signOut } from '@frontrunners/wl-cognito-login'
import { localStorageClear } from '../common/localstorageHelpers'

/**
 * When showing reports, we only want to show reports for the past month once we are at least 10
 * hours into the next month to ensure that all data is available.
 */
export const getTodayWithDataAvailabilityBuffer = () => {
    const todayWithTenHourBuffer = new Date()
    todayWithTenHourBuffer.setUTCHours(todayWithTenHourBuffer.getUTCHours() - 10)
    return todayWithTenHourBuffer
}

export const SignOutAndRedirectUserToLogin = (cognitoPoolId, cognitoClientId) => {
    signOut({ UserPoolId: cognitoPoolId, ClientId: cognitoClientId })
    localStorageClear()
    window.location.replace('/')
}

export const MonthlyReportsContainer = ({merchant, userId, locale, config}) => {
    const oldestReportDate = new Date(merchant.extra_data.oldest_report_date)
    const todayWithDataAvailabilityBuffer = getTodayWithDataAvailabilityBuffer()
    const intl = useIntl()
    const baseUrl = config.get('apiHost')
    const availableReportTypes = getAvailableReportTypes(merchant, intl)
    const [selectedReportType, setSelectedReportType] = useState(availableReportTypes[0])
    const [activeReportTasks, setActiveReportTasks] = useState([])
    const [notifiableTaskIds, setNotifiableTaskIds] = useState(new Set())
    const firstMount = useRef(true)
    const applicationContext = useContext(ApplicationContext)

    const isTaskOfSelectedReportType = (task) => {
        return task.report_type_id === selectedReportType.id
    }

    useEffect(() => {
        if (firstMount.current) {
            pollActiveReportTasks()
        }

        const timer = setInterval(() => {
            pollActiveReportTasks()
        }, 1000)

        if (!firstMount.current && activeReportTasks.every(task => !POLL_STATES.includes(task.status))) {
            clearInterval(timer)
        }

        downloadFinishedReportsOfSelectedType()

        firstMount.current = false
        return () => {
            clearInterval(timer)
        }
    }, [activeReportTasks])

    useEffect(() => {
        activeReportTasks
            .filter(task => !isTaskOfSelectedReportType(task))
            .forEach(task => notifiableTaskIds.add(task.id))
        setNotifiableTaskIds(notifiableTaskIds)
    }, [selectedReportType])

    const downloadFinishedReportsOfSelectedType = () => {
        const reportTasksForDownload = getSelectedReportTasks().filter((task) => task.status === REPORT_TASK_STATES.done)
        reportTasksForDownload.forEach((reportTask) => {
            downloadFile(reportTask)
        })
    }

    const getSelectedReportTasks = () => {
        return activeReportTasks.filter(t => isTaskOfSelectedReportType(t) && !notifiableTaskIds.has(t.id))
    }

    const removeReportTask = (reportTask) => {
        notifiableTaskIds.delete(reportTask.id)
        const newReportTasks = activeReportTasks.filter((task) => task.id !== reportTask.id)
        setActiveReportTasks(newReportTasks)
    }

    const downloadFile = (task) => {
        const error = () => {}
        const success = (_, response) => {
            removeReportTask(task)
            downloadFileFromLink(response.link)
        }

        trackUserActionEvent('Download Monthly Report', window.location, applicationContext, {format: selectedReportType.fileFormat})
        API.getDownloadLink(merchant.id, userId, task.id, baseUrl, success, error)
    }

    const pollActiveReportTasks = () => {
        const error = (err) => {
            if (err.status == 401) {
                SignOutAndRedirectUserToLogin(
                    config.get('UserPoolId'),
                    config.get('ClientId')
                )
            }
        }

        const success = (_, response) => {
            const initialFetch = activeReportTasks.length === 0 && notifiableTaskIds.size === 0
            const applicableReportTypes = new Set(getAvailableReportTypes(merchant, intl).map((reportType) => reportType.id))
            const applicableTasks = response.data.filter((task) => applicableReportTypes.has(task.report_type_id))

            if (initialFetch) {
                setNotifiableTaskIds(new Set(applicableTasks.map(task => task.id)))
            } else {
                applicableTasks.filter((t) => t.status === REPORT_TASK_STATES.failed).forEach((t) => notifiableTaskIds.add(t.id))
                setNotifiableTaskIds(notifiableTaskIds)
            }

            setActiveReportTasks(applicableTasks)
        }

        API.fetchActiveReports(merchant.id, userId, baseUrl, success, error)
    }

    const downloadFileForMonth = (month) => {
        const reportTask = getReportTaskForMonth(month)
        downloadFile(reportTask)
    }

    const dismissReportTaskForMonth = (month) => {
        const task = getReportTaskForMonth(month)
        dismissReportTask(task)
    }

    const dismissReportTask = (task) => {
        const error = () => {}
        const success = () => {
            removeReportTask(task)
        }

        if (task.status !== REPORT_TASK_STATES.done) {
            API.cancelReportTask(merchant.id, userId, task.id, baseUrl, success, error)
        } else {
            // We call getDownloadLink in order to set the report to "consumed"
            API.getDownloadLink(merchant.id, userId, task.id, baseUrl, success, error)
        }
    }

    const getReportTaskForMonth = (month) => {
        const monthAsISOString = dateHelpers.formatDateToUtcIsoString(month)
        return activeReportTasks.find(task => task.from_date === monthAsISOString && isTaskOfSelectedReportType(task))
    }

    const createMonthItem = (month) => {
        const task = getReportTaskForMonth(month)
        const { onClick, buttonIconClass } = createMonthItemContent(task)

        return (
            <div className="report-progress">
                { task && <TaskProgressBar task={task}/> }
                <Button
                    month={month}
                    onClick={onClick}
                    buttonIconClass={buttonIconClass}
                    testId="month-button"
                />
            </div>
        )
    }

    const createMonthItemContent = (task) => {
        if (!task) {
            return { onClick: createReportTask, buttonIconClass: selectedReportType.fileFormat }
        } else if (task.status === REPORT_TASK_STATES.done) {
            return { onClick: downloadFileForMonth, buttonIconClass: selectedReportType.fileFormat }
        } else {
            return { onClick: dismissReportTaskForMonth, buttonIconClass: 'cancel' }
        }
    }

    const Button = ({ month, testId, onClick, buttonIconClass }) => {
        const [disabled, setDisabled] = useState(false)
        return (
            <button data-testid={testId}
                disabled={disabled}
                className="download-btn"
                onClick={() => {
                    setDisabled(true)
                    onClick(month)
            }}>
                <span className={`button-icon ${buttonIconClass}`}/>
            </button>
        )
    }

    const createReportTask = (fromDate) => {
        const toDateTime = dateHelpers.getFirstInNextMonthUTC(new Date(fromDate))

        const toDateISO = dateHelpers.formatDateToUtcIsoString(toDateTime)
        const fromDateISO = dateHelpers.formatDateToUtcIsoString(fromDate)

        const success = (_, response) => {
            activeReportTasks.push(response)
            setActiveReportTasks([...activeReportTasks])
        }

        const error = () => {}

        API.generateReport(baseUrl, selectedReportType, fromDateISO, toDateISO, locale, success, error)
    }

    const hasMonthlyReportsAvailable = () => {
        const monthBreakSinceOldestReportDate = oldestReportDate.getTime() < todayWithDataAvailabilityBuffer.getTime()
            || oldestReportDate.getUTCFullYear() < todayWithDataAvailabilityBuffer.getUTCFullYear()
            || oldestReportDate.getUTCMonth() < todayWithDataAvailabilityBuffer.getUTCMonth()
        const isTrial = TRIAL_STATES.includes(merchant.state)
        return monthBreakSinceOldestReportDate && !isTrial
    }

    return (
        <div className="monthly-reports-container">
            <div className="monthly-reports-header">
                {intl.formatMessage(NAVLINK_TEXTS['navbar-main-link-report'])}
            </div>
            <MonthlyReportsNotificationsContainer
                merchant={merchant}
                activeReportTasks={activeReportTasks}
                dismissReportTask={dismissReportTask}
                downloadFile={downloadFile}
                notifiableTaskIds={notifiableTaskIds}
                locale={locale}
                intl={intl}
            />
            { !hasMonthlyReportsAvailable() ? <NoReportsAvailable /> :
                <div className="report-list-container">
                    <ReportTypeSelector
                        selectedReportType={selectedReportType}
                        setSelectedReportType={(reportType) => {
                            setSelectedReportType(reportType)
                        }}
                        availableReportTypes={availableReportTypes}
                    />
                    <MonthlyReportDescription
                        type={selectedReportType.id}
                        intl={intl}/>
                    <MonthList
                        fromDate={oldestReportDate}
                        toDate={todayWithDataAvailabilityBuffer}
                        locale={locale}
                        createMonthItem={createMonthItem}
                    />
                </div> }
        </div>
    )
}

const NoReportsAvailable = () => {
    const intl = useIntl()
    return (
        <EmptyState>
            <h2 className="text-center">
                {intl.formatMessage(REPORT_EMPTY['header'])}
            </h2>
        </EmptyState>
    )
}

const MonthlyReportContainerWrapper = (props) => {
    const merchants = props.merchant.get('merchants')
    const merchantId = props.match.params.merchantId
    const merchant = getMerchant(merchantId, merchants)
    const userId = props.user.get('sub')
    return <MonthlyReportsContainer merchant={merchant} userId={userId} config={props.config} locale={props.locale} />
}

const mapStateToProps = (state) => {
    return {
        config: state.get('config'),
        merchant: state.get('merchant'),
        user: state.get('user').get('profile'),
        locale: state.getIn(['localization', 'localeCode'])
    }
}

export default connect(mapStateToProps)(withRouter(MonthlyReportContainerWrapper))
