import React from 'react'
import {getAmountString, thousandSeperator} from '../amountHelpers'

const MonetaryAmount = ({ element, amount, amount_exponent, currency, className, hideInsignificantDecimals, excludeCurrency, convertAmountSign}) => {
    let elem = element || {amount:amount, amount_exponent: amount_exponent, currency:currency}
    if(typeof elem.amount === 'undefined'){
        return (<span className={className}>–</span>)
    }
    let elemClass = className || (elem.amount > 0 ? 'fg-success' : '')
    if (convertAmountSign){
        elem.amount = Math.abs(elem.amount) * -1
    }
    let amount_string = getAmountString(elem)
    if (hideInsignificantDecimals){
        let splitted = amount_string.split('.')
        if(splitted[1] && parseInt(splitted[1]) == 0){
            amount_string = splitted[0]
        }
    }

    amount_string = thousandSeperator(amount_string)

    return (
        <span>
            {!excludeCurrency && <span className={elemClass}>{amount_string} {elem.currency}</span>}
            {!!excludeCurrency && <span className={elemClass}>{amount_string}</span>}
        </span>
    )
}

export default MonetaryAmount
