import React, { useEffect, useState } from 'react'
import { Button, Card } from '@frontrunners/ui-components'
import { localStorageGetItem, localStorageSetItem } from '../../common/localstorageHelpers'
import { useIntl } from 'react-intl'
import { CUSTOMER_FEEDBACK_WINDOW as T } from './intlMessageKeyes'
import './customerFeedbackWindow.scss'

export const CustomerFeedbackWindow = () => {
    const { formatMessage } = useIntl()
    const [showWindow, setShowWindow] = useState(false)
    const [cardContentIndex, setCardContentIndex] = useState(0)

    useEffect(() => {
        try {
            const userPreference = JSON.parse(localStorageGetItem('userPreference'))
            if (userPreference?.customerFeedbackWindowClosed !== true) {
                setShowWindow(true)
            }
        } catch (e) {
            setShowWindow(true)
        }
    }, [])

    if (!showWindow) return null

    const closeWindow = () => {
        let userPreference = {}
        try {
            userPreference = JSON.parse(localStorageGetItem('userPreference'))
        } catch (e) { } // eslint-disable-line no-empty
        localStorageSetItem('userPreference', JSON.stringify({ ...userPreference, customerFeedbackWindowClosed: true }))
        setShowWindow(false)
    }

    const cardContent = [
        <>
            <h4>{formatMessage(T['customer_feedback_window_header'])}</h4>
            {formatMessage(T['customer_feedback_window_description'])}
            <div className="buttons">
                <Button
                    label={formatMessage(T['customer_feedback_window_opt_out_button'])}
                    className="blank"
                    onClick={closeWindow}
                />
                <Button
                    label={formatMessage(T['customer_feedback_window_opt_in_button'])}
                    onClick={() => {
                        const userPreference = JSON.parse(localStorageGetItem('userPreference'))
                        localStorageSetItem('userPreference', JSON.stringify({ ...userPreference, customerFeedbackWindowClosed: true }))
                        setCardContentIndex(1)
                    }}
                />
            </div>
        </>,
        <>
            <h4>{formatMessage(T['customer_feedback_window_success_header'])}</h4>
            {formatMessage(T['customer_feedback_window_success_description'])}
            <div className="buttons">
                <Button
                    label={formatMessage(T['customer_feedback_window_success_close'])}
                    onClick={() => {
                        closeWindow()
                    }}
                />
            </div>
        </>
    ]

    return <Card className="customer-feedback-modal" closeButton={true} onClose={closeWindow}>
        {cardContent[cardContentIndex]}
    </Card>
}
