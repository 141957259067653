import React from 'react'
import { useTable, usePagination, useExpanded} from 'react-table'
import { PopUp } from '@frontrunners/ui-components'
import FormattedDate from '../../common/components/formattedDate'
import MonetaryAmount from '../../common/components/monetaryAmount'
import { SETTLEMENT_TABLE_HEADER, SETTLEMENT_DETAILS_LIMIT, SETTLEMENT_PAGINATION_BUTTON } from '../constants'
import SettlementDetailsComponent from './settlementDetailsComponent'
import { handleScroll } from '../../common/stickyScroll'
import { useEffect } from 'react'
import Spinner from '../../common/components/spinner'
import settlements from '../../settlements'
import { useState } from 'react'


const MAX_MULTIMID_TOOLTIP_LENGTH = 5

const MultiMidColumn = (value) => {

    const [showPopUp, setShowPopUp] = useState(false)

    const getPopUpText = (sourceMerchantSet) => {
        const showContinuation = sourceMerchantSet.length > MAX_MULTIMID_TOOLTIP_LENGTH
        const popUpText = sourceMerchantSet.map(
            item => item.source_merchant_id
        ).slice(0, MAX_MULTIMID_TOOLTIP_LENGTH).join('\n')

        return showContinuation ? `${popUpText}\n...` : popUpText
    }

    const sourceMerchantSet = value.row
    const isMultiMid = sourceMerchantSet.length > 1

    return (
        <div className="multimid-column">
            {isMultiMid ?
                <div
                    className="multimid-icon"
                    onMouseEnter={() => setShowPopUp(true)}
                    onMouseLeave={() => setShowPopUp(false)}
                >
                    <PopUp text={getPopUpText(sourceMerchantSet)} visible={showPopUp} className={'animation'} />
                </div> :
                <div>{sourceMerchantSet[0].source_merchant_id}</div>}
        </div>
    )
}


const SettlementList = ({formatMessage, settlements, isFetching, nextPageAvailable, loadMore, tableSize, increaseTableSize, dispatch, merchantId}) => {
    useEffect(() => {
        window.addEventListener('scroll', handleScrollHeader)
        return () => {
            window.removeEventListener('scroll', handleScrollHeader)
        }
    }, [])

    const handleScrollHeader = () => handleScroll('.settlement-data-list .rt-thead.-header .rt-tr', '.settlement-data-list .rt-thead.-header', 200)

    const paginate = () => {
        increaseTableSize()
        loadMore()
    }

    const columns = React.useMemo(() => [
        {
            Header: () => null,
            id: 'expander',
            Cell: row => <div className={`${row.row.isExpanded ? 'expander -open' : 'expander'}`}></div>
        },
        {
            Header: formatMessage(SETTLEMENT_TABLE_HEADER.date),
            id: 'date',
            accessor: 'transfer_date',
            className: 'rt-td date',
            headerClassName: 'rt-th th-head date',
            Cell: row => (
                <FormattedDate date={row.value} />
            )
        },
        {
            Header: formatMessage(SETTLEMENT_TABLE_HEADER.reference),
            accessor: 'reference',
            headerClassName: 'rt-th th-head table-cell-hide-smallest-screen',
            className: 'rt-td table-cell-hide-smallest-screen table-cell-ellipsis reference'
        },
        {
            Header: formatMessage(SETTLEMENT_TABLE_HEADER.merchant_id),
            accessor: 'source_merchant_set',
            headerClassName: 'rt-th th-head table-cell-hide-smallest-screen table-cell-mid merchant-id',
            className: 'rt-td table-cell-hide-smallest-screen table-cell-mid merchant-id',
            Cell: row => (
                <MultiMidColumn row={row.value} />
            )
        },
        {
            Header: formatMessage(SETTLEMENT_TABLE_HEADER.currency),
            accessor: 'currency',
            className: 'rt-td table-cell-hide-medium-screen currency',
            headerClassName: 'rt-th th-head table-cell-hide-medium-screen currency'
        },
        {
            Header: formatMessage(SETTLEMENT_TABLE_HEADER.sales),
            accessor: 'sales_total_sum',
            className: 'rt-td table-cell-hide-medium-screen sales',
            headerClassName: 'rt-th th-head table-cell-hide-medium-screen sales',
            Cell: row => (
                <MonetaryAmount element={row.value} excludeCurrency={true} className="fg-darkest" />
            )
        },
        {
            Header: formatMessage(SETTLEMENT_TABLE_HEADER['fees_adj']),
            accessor: 'fees_total_sum',
            className: 'rt-td table-cell-hide-medium-screen adjustments',
            headerClassName: 'th-head table-cell-hide-medium-screen adjustments',
            Cell: row => (
                <MonetaryAmount element={row.value} excludeCurrency={true} className="fg-darkest" />
            )
        },
        {
            Header: formatMessage(SETTLEMENT_TABLE_HEADER.payout),
            accessor: row => row,
            id: 'payout',
            headerClassName: 'th-head table-cell-small-screen-last-column payout',
            className: 'rt-td table-cell-small-screen-last-column payout',
            Cell: row => (
                <MonetaryAmount element={row.value} excludeCurrency={true} className="fg-darkest" />
            )
        }
    ])

    return (
        <Table
            data={settlements}
            tableSize={tableSize}
            columns={columns}
            isFetching={isFetching}
            nextPageAvailable={nextPageAvailable}
            paginate={paginate}
            dispatch={dispatch}
            merchantId={merchantId}
            formatMessage={formatMessage}
        />
    )
}


const Table = ({columns, data, isFetching, tableSize, paginate, nextPageAvailable, dispatch, merchantId, formatMessage}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow
        } = useTable({
            useControlledState: state => {
                return React.useMemo(
                    () => ({
                        ...state,
                        pageSize: tableSize
                    })
                )
            },
        columns,
        data
    }, useExpanded, usePagination)

    return (
        <React.Fragment>
            <div className="ReactTable-v7 data-list settlement-data-list" {...getTableProps()}>
                <div className="rt-thead -header thead">
                    {headerGroups.map(headerGroup => (
                        <div className="tr-head rt-tr" style={{justifyContent: 'space-between'}} {...headerGroup.getHeaderGroupProps()}>
                            { headerGroup.headers.map(column => (
                                <div style={{flex: column.id === 'expander' ? '35 0 auto' : '100 0 auto', width: column.id === 'expander' ? '35px' : '100px', maxWidth: column.id === 'expander' ? '35px' : 'none'}} {...column.getHeaderProps({className: column.headerClassName})}>
                                    {column.render('Header')}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
                <div className="rt-tbody tbody" {...getTableBodyProps()}>
                    { page.map((row, i ) =>
                        prepareRow(row) || (
                            <React.Fragment key={i}>
                                {!row.original.display_hint &&
                                    <div className={`${row.isExpanded ? 'expanded' : ''} tr-body rt-tr`} {...row.getRowProps()} {...row.getToggleRowExpandedProps({
                                        onClick: () => {
                                            row.toggleRowExpanded()
                                            if(!row.isExpanded && !row.original.transactions_available) {
                                                refreshSettlement(dispatch, merchantId, row.original.id)
                                            }
                                            page.map(r => {
                                                if(r.id !== row.id && r.isExpanded) {
                                                    r.toggleRowExpanded()
                                                }
                                            })
                                        }
                                    })}>
                                        {row.cells.map(cell => {
                                            return (
                                                <div className="td-body rt-td" style={{flex: cell.column.id === 'expander' ? '35 0 auto' : '100 0 auto', width: cell.column.id === 'expander' ? '35px' : '100px', maxWidth: cell.column.id === 'expander' ? '35px' : 'none'}} {...cell.getCellProps({className: cell.column.className})}>{cell.render('Cell')}</div>
                                            )
                                        })}
                                    </div >
                                }
                                { row.isExpanded ? (
                                    <div className="sub-component">
                                        <SettlementDetailsComponent
                                            disableDownloadBtn={!row.original.transactions_available}
                                            settlement={row.original}
                                            limit={SETTLEMENT_DETAILS_LIMIT}
                                        />
                                    </div>
                                ) : null }
                            </React.Fragment>
                        )
                    )}
                </div>
            </div>
            {isFetching && <Spinner waiting={isFetching} />}
            { nextPageAvailable && !isFetching &&<div className="pagination-btn">
                <button style={{ marginBottom: '50px' }} className="rebranded-primary-btn" onClick={() => paginate()}>
                    {formatMessage(SETTLEMENT_PAGINATION_BUTTON['pagination-btn-load-more-state'])}
                </button>
            </div> }
        </React.Fragment>
    )
}


const refreshSettlement = (dispatch, merchantId, settlementId) => {
    setTimeout(() => {
      dispatch(settlements.actions.getSettlement(merchantId, settlementId))
    })
  }

export default SettlementList
