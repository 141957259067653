import React, { useMemo, useContext } from 'react'
import { getProgressPercent } from '../helpers'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import './fileComponent.scss'
import { REPORT_HOVERTEXT } from '../constants'
import { trackUserActionEvent } from '../../common/helpers/mixpanelTrackers'
import { ApplicationContext } from '../../common/reactContext'


const FileComponent = React.forwardRef((props, ref) => {
    const { report, formatMessage, date, isText = true, downloadReport, disabled, disabledTooltipText } = props
    const applicationContext = useContext(ApplicationContext)

    const progressPercent = report.loading && report.loading === 'DONE' ? 100 : getProgressPercent(report.items_fetched, report.items_total)
    const iconClassName = `icon-${report.format}`
    const loadingClassName = report.loading ? 'file-component--loading' : ''
    const disabledClass = disabled ? 'disabled-btn' : ''

    const reportFailedClass = report.error ? 'failed' : ''

    const startDownloadReport = () => {
        if (report.error) return
        if (disabled) return
        if ((!ref || !ref.current || !ref.current.href || ref.current.href!=='#') && !report.loading) {
            downloadReport(date, report, ref)

            const reportType = window.location.pathname.includes('sales') ? 'Sales' : 'Payout'
            trackUserActionEvent(`Download ${reportType} Report`, window.location, applicationContext, {format: report.format})
        }
    }
    const file = useMemo(() => <div className={`file-component ${reportFailedClass} ${loadingClassName} ${disabledClass}`} onClick={() => startDownloadReport()}>
        <a className={`file-component__icon ${reportFailedClass} ${iconClassName}`} ref={ref}>
            {report.loading && <CircularProgressbar value={progressPercent / 100} maxValue={1} styles={buildStyles({
                pathColor: 'rgba(69, 190, 170, 1)'
            })} />
        }
        </a>
        { isText && report.reportTitle && <div>{formatMessage(report.reportTitle)}</div> }
        { report.error && <div className="error-container">
            <div className="error-notification"></div>
            <div className="error-text">{formatMessage(REPORT_HOVERTEXT['error'])}</div>
        </div>}

        <span className="disable-btn-tooltip">{disabledTooltipText}</span>
    </div>, [report.loading, report.items_fetched, report.items_total, disabled, report.error])

    return (
        <div> {file}</div>
    )
})

export default FileComponent
