import React, { useContext, useState, useRef } from 'react'
import { useIntl } from 'react-intl'
import { formatISO } from 'date-fns'
import { ApplicationContext } from '../../../common/reactContext'
import { getContracts, downloadContractPdf } from '../../../common/contractQueries'
import { CONTRACTS_TEXTS as T } from './intlMessageKeys'
import { Paginator, NotificationContainer, Notification } from '@frontrunners/ui-components'
import Spinner from '../../../common/components/spinner'
import { trackUserActionEvent } from '../../../common/helpers/mixpanelTrackers'
import './contracts.scss'
import { ROLES } from '../../../merchant/constants'


const PAGE_LENGTH = 5

export const Contracts = () => {
    const { formatMessage: fm } = useIntl()
    const { activeMerchant, config, loggedInUser } = useContext(ApplicationContext)

    if (loggedInUser.role != ROLES.OWNER) return

    const allContracts = useRef([])
    const [contracts, setContracts] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const lastPageNumber = Math.ceil(allContracts.current.length / PAGE_LENGTH)
    const showPaginator = allContracts.current.length > PAGE_LENGTH

    const loadNextPage = (e) => {
        const idx = Number(e) - 1
        const start = idx * PAGE_LENGTH
        const end = idx * PAGE_LENGTH + PAGE_LENGTH
        setContracts(allContracts.current.slice(start, end))
        setCurrentPage(e)
    }

    const { isLoading, data } = getContracts(config.apiHost, activeMerchant.id, 'WLX')

    if (!isLoading && allContracts.current.length === 0 && data.data.length > 0) {
        allContracts.current = data.data
        setContracts(allContracts.current.slice(0, PAGE_LENGTH))
    }

    if (isLoading) {
        return <Spinner waiting={true} />
    }

    if (!isLoading && contracts.length === 0) {
        return null
    }

    return (
        <div className="contracts">
            <h2 className="settings-paper-header">
                {fm(T.contracts_header)}
            </h2>

            <ContractsTable contracts={contracts} />

            <div className="contracts-footer">
                <div className="current-page">
                    {fm(T.contracts_current_page, {
                        currentPage: currentPage,
                        totalPages: lastPageNumber
                    })}
                </div>
                {showPaginator &&
                    <Paginator
                        currentPage={currentPage}
                        onChangeAction={(e) => loadNextPage(e)}
                        totalPages={lastPageNumber}
                    />
                }
            </div>
        </div>
    )
}

const ContractsTable = ({ contracts }) => {
    const { formatMessage: fm } = useIntl()
    const [pdfLinkErrors, setPdfLinkErrors] = useState([])

    return (
        <>
            <table>
                <thead>
                    <tr className="settings-paper-subheader">
                        <th className="contract-product-name">
                            {fm(T.contracts_table_column_product)}
                        </th>
                        <th className="contract-acquirer-id">
                            {fm(T.contracts_table_column_id)}
                        </th>
                        <th className="contract-start-date">
                            {fm(T.contracts_table_column_start_time)}
                        </th>
                        <th className="contract-termination-date">
                            {fm(T.contracts_table_column_terminaton_date)}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {contracts.map(contract => {
                        const startTime = formatISO(new Date(contract.created_at), { representation: 'date' })
                        return (
                            <tr key={contract.id}>
                                <td className="contract-product-name">
                                    <ContractPdfLink contract={contract} onDownloadError={() => setPdfLinkErrors((list) => (
                                        [
                                            ...list,
                                            {
                                                iconName: 'warning2',
                                                headerContent: fm(T.contracts_table_error_modal_header),
                                                textContent: `${fm(T.contracts_table_error_modal_description_1)} ${fm(T.contracts_table_error_modal_description_2)}`
                                            }

                                        ]
                                    ))}
                                    />
                                </td>
                                <td className="contract-acquirer-id">
                                    {contract.acquirer_mid}
                                </td>
                                <td className="contract-start-date">
                                    {startTime}
                                </td>
                                <td className="contract-termination-date">
                                    {contract.termination_date}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <NotificationContainer>
                {pdfLinkErrors && pdfLinkErrors.map((notification, index) => (
                    <Notification key={index} {...notification} />
                ))}
            </NotificationContainer>
        </>
    )
}

const ContractPdfLink = ({ contract, onDownloadError }) => {
    const applicationContext = useContext(ApplicationContext)
    const { activeMerchant, config } = applicationContext
    const [isLoadingContractPdf, setIsLoadingContractPdf] = useState(null)

    if (isLoadingContractPdf == contract.id) {
        return <Spinner waiting={true} />
    }

    return <>
        <a onClick={async () => {
            trackUserActionEvent('Download Contract', window.location, applicationContext, {format: 'pdf'})

            setIsLoadingContractPdf(contract.id)
            downloadContractPdf(config.apiHost, activeMerchant.id, contract.id)
                .then(() => {
                    setIsLoadingContractPdf(null)
                })
                .catch(() => {
                    onDownloadError()
                    setIsLoadingContractPdf(null)
                })
        }}
            className="download-contract-pdf">
            {getProductName(contract.product)}
        </a>
    </>
}

const getProductName = (product) => {
    const productName = {
        ACCEPTANCE_ECOM: 'Worldline Acquiring',
        ACCEPTANCE_POS: 'Worldline Acquiring',
        ACCEPTANCE_POS_API: 'Worldline Acquiring',
        ACCEPTANCE_USER: 'Worldline Acquiring',
        ACCEPTANCE_USER_GROW: 'Worldline Acquiring',
        BAMBORA_CONNECT: 'Worldline Connect',
        BAMBORA_DEVICE: 'Worldline Device',
        BAMBORA_DEVICE_SHORT_TERM: 'Worldline Device Short Term',
        BAMBORA_INSTORE_GROW: 'Worldline Grow',
        BAMBORA_ONE: 'Worldline One',
        BAMBORA_ONE_SHORT_TERM: 'Worldline One Short Term',
        CHECKOUT: 'Worldline Checkout',
        NATIVE: 'Worldline Native',
        PAYFORM: 'Worldline Payform'
    }
    return productName[product]
}
