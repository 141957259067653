import mixpanel from 'mixpanel-browser'


export const trackEnterPageEvent = (location, applicationContext) => {
    const properties = getEventProperties(location, applicationContext)
    mixpanel.track('Enter Page', properties)
}

export const trackUserActionEvent = (userAction, location, applicationContext, additionalProperties = {}) => {
    const properties = getEventProperties(location, applicationContext, {...additionalProperties, 'User Action': userAction})
    mixpanel.track('User Action', properties)
}

const getEventProperties = (location, applicationContext, additionalProperties = {}) => {
    const {config, activeMerchant, loggedInUser} = applicationContext
    return {
        'Page': getPageName(location),
        'App Version': config.gitCommitVersion,
        'User Role': getUserRole(loggedInUser.role),
        'User ID': loggedInUser.sub,
        'Merchant has Acquiring': activeMerchant.hasAcquiring,
        'Merchant has Online': activeMerchant.hasOnline,
        'Merchant has Instore': activeMerchant.hasInstore,
        'Merchant ID': activeMerchant.id,
        'Host': window.location.host,
        ...additionalProperties,
        'Mixpanel Event Model': '2.0.0'
    }
}

export const getPageName = (location) => {
    const locationParts = location.pathname.split('/')
    const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1)

    if (locationParts.length > 3) {
        return locationParts.slice(3).map(capitalize).join('/').replace(/\/$/, '')
    }
    if (locationParts.length === 3) {
        return 'Dashboard'
    }
    return 'Select Merchant'
}

const getUserRole = (role) => {
    switch (role) {
        case 'merchant_admin':
            return 'Owner'
        case 'merchant_admin_non_owner':
            return 'Admin'
        case 'merchant_user':
            return 'User'
        default:
            return role
    }
}
