import React from 'react'
import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'

import './salesInstoreRefund.scss'

import { TRANSACTION_TYPE } from '../../../settlements/constants'
import BamboraSvgIcon from '../../../common/components/bamboraSvgIcon'
import { stringWithExponentToInteger, getExponentedAmountString, validateExponent } from '../../../common/amountHelpers'
import Spinner from '../../../common/components/spinner'
import { ORDER_OPERATION_MODAL } from '../../online/translations'
import { INSTORE_REFUND_TEXTS, SALES_DETAILS_TEXTS } from '../constants'
import requestHelper from '../../../helpers/requestHelper'


const InstoreRefund = ({payment, formatMessage, config, merchantId, exponent, currency, instoreLocalRefundedAmount, updateInstoreLocalRefundedAmount, addLocalOperations, disableRefundButton}) => {
    const [showRefundModal, openRefundModal] = useState(false)
    const closeRefundModal = () => {
        openRefundModal(false)
    }

    return <div className="instore-actions">
        <button data-testid="instore-detail-refund-btn" disabled={disableRefundButton} className={`rebranded-primary-btn ${disableRefundButton ? 'disabled' : ''}`}
                onClick={() => openRefundModal(true)}>
                    Refund
        </button>
        { showRefundModal && <InstoreRefundModal
            closeRefundModal={closeRefundModal}
            showRefundModal={showRefundModal}
            payment={payment}
            formatMessage={formatMessage}
            exponent={exponent}
            currency={currency}
            merchantId={merchantId}
            config={config}
            instoreLocalRefundedAmount={instoreLocalRefundedAmount}
            updateInstoreLocalRefundedAmount={updateInstoreLocalRefundedAmount}
            addLocalOperations={addLocalOperations}
        />}
    </div>
}

export const InstoreRefundModal = ({closeRefundModal, showRefundModal, payment, formatMessage, exponent, currency, merchantId, config, instoreLocalRefundedAmount, updateInstoreLocalRefundedAmount, addLocalOperations}) => {
    const MAXIMUM_INPUT_LENGTH = 100
    const availableAmount = payment.refundable_amount - instoreLocalRefundedAmount

    const [amountToRefund, updateAmountToRefund] = useState(0)
    const [reason, updateReason] = useState(null)
    const [invalidAmountInput, setInvalidAmountInput] = useState(null)
    const [inputErrorMessage, setInputErrorMessage] = useState(null)
    const [invalidReasonInput, setInvalidReasonInput] = useState(false)
    const [localOperation, setLocalOperation] = useState(null)
    const [isLoading, setLoading] = useState(false)
    const [success, setSuccess] = useState(null)
    const [error, setError] = useState(false)
    const [refunded, setRefunded] = useState(false)
    const baseUrl = config.get('apiHost')

    const onChangeAmount = (amount) => {
        if(amount === '') updateAmountToRefund(0)

        if(!validateExponent(amount, exponent)) {
            let error = formatMessage(INSTORE_REFUND_TEXTS['invalid_input_amount'])
            setInvalidAmountInput(true)
            setInputErrorMessage(error)
            return
        }

        if(stringWithExponentToInteger(amount, exponent) > availableAmount) {
            let error = formatMessage(INSTORE_REFUND_TEXTS['maximum_refund'])
            setInvalidAmountInput(true)
            updateAmountToRefund(0)
            setInputErrorMessage(`${error}: ${getExponentedAmountString(availableAmount, exponent)} ${currency}`)
            return
        }

        updateAmountToRefund(stringWithExponentToInteger(amount, exponent))
        setLocalOperation({
            amount: stringWithExponentToInteger(amount, exponent),
            message: reason ? reason : null,
            processing_datetime_utc: new Date().toISOString(),
            processing_datetime: new Date().toISOString(),
            currency_exponent: exponent,
            currency_code: currency,
            success: true,
            operation_type: 'Refunded'
        })
        setInvalidAmountInput(false)
    }

    const refundTransaction = (paymentId, amount, reason) => {
        const path = `/v1/merchants/${merchantId}/instore/payments/${paymentId}/refund`
        let requestBody = {
            amount: amount
        }

        if(reason) requestBody.message = reason
        const additionalHeader = {'Api-Version': 1}

        setLoading(true)

        requestHelper(baseUrl, path, onRefundSuccess, onRefundFail, null, requestBody, 'POST', additionalHeader)
    }

    const onRefundSuccess = () => {
        addLocalOperations(localOperation)
        setSuccess(true)
        setLoading(false)
        setRefunded(true)
        updateInstoreLocalRefundedAmount(parseInt(amountToRefund) + parseInt(instoreLocalRefundedAmount))
        setTimeout(() => [
            closeRefundModal()
        ], 1500)
    }

    const onRefundFail = () => {
        setLocalOperation(null)
        setSuccess(false)
        setLoading(false)
        setRefunded(true)
        setError(true)
    }

    const onChangeReason = (reason) => {
        if(reason.length > MAXIMUM_INPUT_LENGTH) {
            setInvalidReasonInput(true)
            return
        }
        setInvalidReasonInput(false)
        updateReason(reason)
    }

    return (
        <React.Fragment>
            <Modal data-testid="instore-refund-modal" className="bootstrap-modal instore-refund-modal" animation={false} show={showRefundModal} onHide={closeRefundModal} centered={true} backdrop={'static'}>
                { !isLoading && !refunded && <React.Fragment><Modal.Header className="instore-operation-header">
                    <h3>{formatMessage(TRANSACTION_TYPE['refund'])}</h3>
                    <div className="close-modal">
                        <BamboraSvgIcon className="fill-inherit" icon={'close-thin'} onClick={() => closeRefundModal()}/>
                    </div>
                </Modal.Header>
                <Modal.Body className="instore-operation-body">
                    <div className="instore-refund payment">
                        {formatMessage(SALES_DETAILS_TEXTS['payment_instore_transaction_id'])} {payment.payment_id}
                    </div>
                    <div className="instore-refund amount">
                        <input data-testid="input-amount" type="number" className={`${invalidAmountInput ? 'invalid' : ''}`} placeholder={formatMessage(ORDER_OPERATION_MODAL['amount_to_refund'])} onChange={(e) => onChangeAmount(e.target.value)}/>
                        { invalidAmountInput && <div className="instore-refund-amount-input-error">{inputErrorMessage}</div> }
                    </div>
                    <div className="instore-refund reason">
                        <input data-testid="input-reason" type="text" className={`${invalidReasonInput ? 'invalid' : ''}`} placeholder="Reason for refund" onChange={(e) => onChangeReason(e.target.value)}/>
                        { invalidReasonInput && <div className="instore-refund-amount-input-error">{formatMessage(INSTORE_REFUND_TEXTS['invalid_input_reason'])}: {MAXIMUM_INPUT_LENGTH}</div> }
                    </div>
                    <div className="instore-refund amount-available">
                        <div className="text">{formatMessage(ORDER_OPERATION_MODAL['amount_available'])} </div>
                        <div data-testid="amount-available" className="amount">{getExponentedAmountString(availableAmount, exponent)} {currency}</div>
                    </div>
                    <div className="instore-refund amount-to-be-refunded">
                        <div className="text">{formatMessage(ORDER_OPERATION_MODAL['amount_to_refund'])}</div>
                        <div data-testid="amount-to-refund" className="amount amount-to-refund">{getExponentedAmountString(amountToRefund, exponent)} {currency}</div>
                    </div>
                    <div className="instore-refund buttons">
                        <button className="rebranded-primary-btn"
                                data-testid="refund-btn-instore"
                                disabled={invalidAmountInput || invalidReasonInput || amountToRefund <= 0}
                                onClick={() => refundTransaction(payment.payment_id, amountToRefund, reason)}>
                                    {formatMessage(ORDER_OPERATION_MODAL['refund'])}
                        </button>
                        <button className="rebranded-secondary-btn"
                                style={{marginLeft: '15px'}}
                                onClick={() => closeRefundModal()}>
                                    {formatMessage(ORDER_OPERATION_MODAL['cancel'])}
                        </button>
                    </div>
                </Modal.Body></React.Fragment>}
                { (isLoading || success) && <div className="instore-refund refund-spinner">
                        <Spinner waiting={isLoading} success={success}/>
                    </div>
                }
                { (error && !isLoading && !success) && <ErrorModal formatMessage={formatMessage} closeRefundModal={closeRefundModal}/>}
            </Modal>
        </React.Fragment>
    )
}

const ErrorModal = ({formatMessage, closeRefundModal}) => {
    return (
        <React.Fragment>
            <div className="error close-modal">
                <BamboraSvgIcon className="fill-inherit" icon={'close-thin'} onClick={() => closeRefundModal()}/>
            </div>
            <div className="instore-refund refund-spinner">
                <div className="error"></div>
                {formatMessage(ORDER_OPERATION_MODAL['refund_error'])}
            </div>
        </React.Fragment>
    )
}

export default InstoreRefund
