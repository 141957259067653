import React, { useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import { Paper, Accordion, Icon, Dropdown, PopUp } from '@frontrunners/ui-components'
import Spinner from '../../common/components/spinner'
import { TERMINALS_TEXTS as T } from './intlMessageKeys'
import { FEATURE_DISABLED } from '../../common/translations'
import { getTerminalsQuery } from '../../common/instoreQueries'
import { ApplicationContext } from '../../common/reactContext'
import { trackUserActionEvent } from '../../common/helpers/mixpanelTrackers'
import './terminals2.scss'


const ROWS_PER_PAGE = 10


export const Terminals = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const accountFilter = searchParams.get('filter')

    const [selectedAccountIds, setSelectedAccountIds] = useState(accountFilter ? [accountFilter] : [])

    const { formatMessage: fm } = useIntl()
    const { config, loggedInUser, activeMerchant } = useContext(ApplicationContext)

    const {
        data,
        isLoading,
        fetchNextPage,
        isFetchingNextPage
    } = getTerminalsQuery(config.apiHost, activeMerchant.id, ROWS_PER_PAGE, !loggedInUser.isSupport)
    const moreTerminalsToFetch = data?.pages[data.pages.length - 1]?.length >= ROWS_PER_PAGE

    const allTerminals = data?.pages?.flat()
    const accountIds = allTerminals ? getAccountIdsWithTerminals(allTerminals) : []
    const showAccountIdDropdown = accountIds.length > 1
    const filteredTerminals = applyTerminalsFilter(allTerminals, selectedAccountIds)

    if (!isFetchingNextPage && moreTerminalsToFetch) {
        fetchNextPage()
    }

    const setSystemIdFilter = (_, event) => setSelectedAccountIds(event.map(e => e.text))

    return (
        <Paper className="terminals">
            {showAccountIdDropdown &&
                <Dropdown
                    label={fm(T['account_id'])}
                    multiSelect
                    items={accountIds}
                    onChange={setSystemIdFilter}
                    defaultSelectedItems={accountFilter ? [{text: accountFilter}] : []}
                />
            }

            <h2 className="settings-paper-header">
                {fm(T['terminals'])}
            </h2>

            <Columns />

            <TerminalsList
                terminals={filteredTerminals}
                isFetching={isLoading || isFetchingNextPage}
            />
        </Paper>
    )
}

export const TerminalsList = ({ terminals, isFetching }) => {
    const { formatMessage: fm } = useIntl()

    if (terminals?.length === 0 && !isFetching) {
        return (
            <div className="no-terminals">
                {fm(T['no_terminals_available'])}
            </div>
        )
    }

    return (
        <>
            {
                terminals?.map(terminal => {
                    return (
                        <TerminalDetails
                            key={terminal.id}
                            terminal={terminal}
                        />
                    )
                })
            }

            { isFetching && <div className="terminals-spinner"><Spinner waiting={true} /></div> }
        </>
    )
}

const Columns = () => {
    const { formatMessage: fm } = useIntl()

    return (
        <div className="columns settings-paper-subheader">
            <div className="terminal-id">
                {fm(T['terminal_id'])}
            </div>
            <div className="terminal-name">
                {fm(T['merchant_user_name'])}
            </div>
            <div className="account-id">
                {fm(T['account_id'])}
            </div>
            <div className="terminal-password">
                {fm(T['password'])}
            </div>
        </div>
    )
}

const TerminalDetails = ({ terminal }) => {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <Accordion
            isOpen={isOpen}
            onToggleClick={() => setIsOpen(!isOpen)}
            header={
                <DetailsHeader terminal={terminal} />
            }
            content={
                <DetailsBody terminal={terminal} isOpen={isOpen} setIsOpen={setIsOpen} />
            }
        />
    )
}

const DetailsHeader = ({ terminal }) => {
    const { formatMessage: fm } = useIntl()
    const { loggedInUser } = useContext(ApplicationContext)
    const showPassword = !!terminal.password || loggedInUser.isSupport

    return (
        <div className="details">
            <div className="toggler-desktop" >
                <Icon iconName="chevronDown" className="toggle-icon" />
            </div>
            <div className="terminal-id">
                <h4>{fm(T['terminal_id'])}</h4>
                <p>{terminal.id}</p>
            </div>
            <div className="terminal-name">
                <h4>{fm(T['merchant_user_name'])}</h4>
                <p>{terminal.salesLocation?.name || '-'}</p>
            </div>
            <div className="account-id">
                <h4>{fm(T['account_id'])}</h4>
                <p>{terminal.merchant.id}</p>
            </div>
            <div className={`terminal-password ${showPassword ? '' : 'disabled'}`}>
                <Password
                    password={terminal.password}
                    disabled={loggedInUser.isSupport}
                />
                {loggedInUser.isSupport &&
                    <PopUp
                        text={fm(FEATURE_DISABLED['disabled_for_support_agents'])}
                        visible
                    />
                }
            </div>
            <div className="toggler-mobile" >
                {fm(T['show_more'])}
                <Icon iconName="chevronDown" className="toggle-icon" />
            </div>
        </div>
    )
}

const DetailsBody = ({ terminal, isOpen, setIsOpen }) => {
    const { formatMessage: fm } = useIntl()

    const onText = fm(T['on'])
    const offText = fm(T['off'])

    const tip = terminal.license.tip?.enabled ? onText : offText
    const contactless = terminal.license.contactless ? onText : offText
    const autoCapture = isAutoCaptureActive(terminal.license.settleTime) ? onText : offText

    return (
        <div className="details-body">
            <div className="tip">
                <div className="detail">
                    {fm(T['tip'])}
                </div>
                <div className="text">
                    {tip}
                </div>
            </div>
            <div className="contactless">
                <div className="detail">
                    {fm(T['contactless'])}
                </div>
                <div className="text">
                    {contactless}
                </div>
            </div>
            <div className="model-name">
                <div className="detail">
                    {fm(T['terminal_model'])}
                </div>
                <div className="text">
                    {terminal.modelName}
                </div>
            </div>
            <div className="auto-capture">
                <div className="detail">
                    {fm(T['direct_capture'])}
                </div>
                <div className="text">
                    {autoCapture}
                </div>
            </div>
            <div className="toggler" onClick={() => setIsOpen(!isOpen)}>
                {fm(T['show_less'])}
                <Icon iconName="chevronDown" className="toggle-icon" />
            </div>
        </div>
    )
}

const Password = ({ password, disabled }) => {
    const { formatMessage: fm } = useIntl()
    const applicationContext = useContext(ApplicationContext)
    const [showPassword, setShowPassword] = useState(false)

    const toggleShowPassword = (event) => {
        event.stopPropagation()

        if (!disabled) {
            setShowPassword(showPassword => !showPassword)

            trackUserActionEvent('Toggle Terminal Password', window.location, applicationContext, {status: showPassword ? 'hidden' : 'visible'})
        }
    }

    const icon = <Icon
        iconName={showPassword ? 'eyeDisabled' : 'eye'}
        className="show-secret"
    />

    return (
        <div className={`password ${disabled ? 'disabled' : ''}`} data-testid="password" onClick={toggleShowPassword}>
            {showPassword ?
                <p className="visible-password">{password}</p> :
                <div className="hidden-password">
                    <p className="desktop">••••••</p>
                    <p className="mobile">{fm(T['password'])}</p>
                </div>
            }
            { icon }
        </div>
    )
}

const isAutoCaptureActive = (settleTime) => {
    return !(settleTime === undefined || settleTime === 'Manual')
}

const applyTerminalsFilter = (terminals, selectedAccountIds) => {
    if (selectedAccountIds.length > 0 && terminals) {
        return terminals.filter(terminal =>
            selectedAccountIds.includes(terminal.merchant.id.toString())
        )
    }
    return terminals
}

const getAccountIdsWithTerminals = (data) => {
    const allAccountIds = new Set([])
    const accountsWithTerminals = []

    data.map(m =>
        allAccountIds.add(m.merchant.id)
    )
    allAccountIds.forEach(accountId =>
        accountsWithTerminals.push({text: String(accountId)})
    )
    return accountsWithTerminals
}
