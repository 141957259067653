import types from './actionTypes'
import { runRequest } from './../../common/requestActionHelper'
import { INSTORE_BASE, PAYMENTS_BASE, PAYMENTS_BASE_HEADERS } from './../../common/constants'

const getDetailsStart = () => {
    return {
        type: types.GET_DETAILS_START
    }
}

const getDetailsSuccess = (payment) => {
    return {
        type: types.GET_DETAILS_SUCCESS,
        payload: { payment }
    }
}

const getDetailsError = () => {
    return {
        type: types.GET_DETAILS_ERROR
    }
}

export const getDetails = (merchantId, paymentId) => {
    const url = `${INSTORE_BASE}/${paymentId}?update_refundable_amount=true&add_refund_operations=true`
    return runRequest({
        startAction: () => getDetailsStart(),
        successAction: (payment) => {
            return (dispatch) => {
                if(payment.payment_id) {
                    dispatch(getSettledInformationForPayment(merchantId, paymentId))
                }
                dispatch(getDetailsSuccess(payment))
            }
        },
        errorAction: getDetailsError,
        requestObj: {
            url,
            headers: PAYMENTS_BASE_HEADERS,
            merchantId
        }
    })
}

const getSettledInformationForPaymentStart = () => {
    return {
        type: types.GET_SETTLED_INFORMATION_START
    }
}

const getSettledInformationForPaymentSuccess = (payments) => {
    return {
        type: types.GET_SETTLED_INFORMATION_SUCCESS,
        payload: { payments }
    }
}

const getSettledInformationForPaymentError = () => {
    return {
        type: types.GET_SETTLED_INFORMATION_ERROR
    }
}

const getSettledInformationForPayment = (merchantId, paymentId) => {
    let url = `${PAYMENTS_BASE}?reference=${paymentId}`
    return runRequest({
        startAction: () => getSettledInformationForPaymentStart(),
        successAction: (payments) => {
            return getSettledInformationForPaymentSuccess(payments)
        },
        errorAction: getSettledInformationForPaymentError,
        requestObj: {
            url,
            cache: true,
            merchantId
        }
    })
}
