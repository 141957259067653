import React, { useState } from 'react'
import uuid from 'uuid'
import Carousel from 'react-bootstrap/Carousel'
import Modal from 'react-bootstrap/Modal'
import { WELCOME_TEXT } from '../constants'
import './userguide.scss'
import BamboraSvgIcon from '../../common/components/bamboraSvgIcon'
import getData from '../helpers/getDataForWelcomeGuide'


const UserGuideCard = ({ item, onModalClose, formatMessage }) => {
    const { title, img, text, id } = item
    const onClick = () => {
        onModalClose()
    }
    return (
        <div>
            <div className="user-guide-card">
                <div className="img-wrapper">
                    <img src={img} width="120" height="120" alt="" />
                </div>
                <h2 className="">{title}</h2>
                {text && <p>{text} </p>}
                {id === 'END' && <button className="rebranded-primary-btn" onClick={onClick}>{formatMessage(WELCOME_TEXT['start'])}</button>}
            </div>
        </div>
    )
}

const UserGuideContent = ({ slideData, onModalClose, formatMessage }) => {

    return (
        <div className="user-guide-wrapper">
            <div className="user-guide">
                <Carousel
                    nextIcon=""
                    prevIcon=""
                    interval={null}
                    wrap={false}
                    slide={false}
                    nextLabel={formatMessage(WELCOME_TEXT['next-page'])}
                    prevLabel={formatMessage(WELCOME_TEXT['prev-page'])}
                >
                    {slideData.map(item => (
                        <Carousel.Item key={uuid()}>
                            <UserGuideCard item={item} onModalClose={onModalClose} formatMessage={formatMessage} />
                            {item.element_id && (item.highLightText || item.logo) && <HighLight logo={item.logo} element_id={item.element_id} highLightText={item.highLightText} />}
                        </Carousel.Item>
                    ))}
                </Carousel></div>
        </div>

    )
}

const HighLight = ({ element_id, highLightText, logo }) => {

    const element = document.getElementById(element_id)
    const divStyle = element && {
        width: element.offsetWidth + 'px',
        height: element.offsetHeight + 'px',
        top: element.offsetTop + 'px',
        left: element.offsetLeft + 'px',
        background: 'white',
        color: 'black'
    }
    const Logo = logo

    return (
        <span>
            {element && <div className="user-guide-highlight" style={divStyle}>
                {highLightText}
                {logo && <Logo />}
            </div>}
        </span>

    )
}

const UserGuide = ({ isLoanEligible, merchantObject, onClose, formatMessage }) => {
    const [show, setShow] = useState(true)

    const onModalClose = () => {
        setShow(false)
        onClose()
    }

    const slideData = getData(isLoanEligible, merchantObject, formatMessage)

    return (
        <div>
            <Modal className="bootstrap-modal user-guide-modal" animation={false} show={show} onHide={() => onModalClose()} centered={true} backdrop={'static'}>
                <Modal.Header closeButton>
                </Modal.Header>

                <Modal.Body>
                    <UserGuideContent slideData={slideData} onModalClose={onModalClose} formatMessage={formatMessage} />
                </Modal.Body>

            </Modal>
            <div className="highlightarrow">
                <BamboraSvgIcon icon="arrow-left" />
            </div>
        </div>
    )
}

export default UserGuide
