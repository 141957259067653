import t from './actionTypes'
import { runRequest } from '../../common/requestActionHelper'
import { PAYMENTS_BASE, PAYMENTS_BASE_HEADERS } from '../../common/constants'
import { setPaymentMethod, getAuthRetrievalReferenceNumber, getRefundRetrievalReferenceNumber } from './helpers'

export function getDetails(merchantId, paymentId) {
    const url = `${PAYMENTS_BASE}/${paymentId}/?with_order_information=true`
    return runRequest({
        startAction: () => getDetailsStart(url),
        successAction: (order) => {
            order = setPaymentMethod(order)
            return (dispatch) => {
                    const authRetrievalReferenceNumber = getAuthRetrievalReferenceNumber(order)
                    if (authRetrievalReferenceNumber) {
                        dispatch(getSettledTransactionsByReference(merchantId, authRetrievalReferenceNumber))
                    }
                    const refundRetrievalReferenceNumber = getRefundRetrievalReferenceNumber(order)
                    if (refundRetrievalReferenceNumber) {
                        dispatch(getRefundsByReference(merchantId, refundRetrievalReferenceNumber))
                    }
                return dispatch(getDetailsSuccess(paymentId, order))
            }

        },
        errorAction: getDetailsError,
        requestObj: {
            url,
            headers: PAYMENTS_BASE_HEADERS,
            merchantId
        }
    })
}

function getDetailsStart() {
    return {
        type: t.GET_DETAILS_START
    }
}

function getDetailsError(paymentId) {
    return {
        type: t.GET_DETAILS_ERROR,
        payload: {
            id: paymentId
        }
    }
}

function getDetailsSuccess(paymentId, order) {
    return emitAndUpdateInList({
        type: t.GET_DETAILS_SUCCESS,
        payload: {
            id: paymentId,
            order
        }
    })
}

function listSettledStart() {
    return {
        type: t.LIST_SETTLED_START
    }
}

export const listSettledSuccess = (transactions) => {
    return {
        type: t.LIST_SETTLED_SUCCESS,
        isFetching: false,
        payload: {
            transactions: transactions
        }
    }
}

function listRefundsStart() {
    return {
        type: t.LIST_REFUNDS_START
    }
}

export const listRefundsSuccess = (transactions) => {
    return {
        type: t.LIST_REFUNDS_SUCCESS,
        isFetching: false,
        payload: {
            transactions: transactions
        }
    }
}

export function getSettledTransactionsByReference(merchantId, reference) {
    let url = `${PAYMENTS_BASE}?reference=${reference}`
    return runRequest({
        startAction: () => listSettledStart(url),
        successAction: (transactions) => {
            return listSettledSuccess(transactions)
        },
        errorAction: listSettledError,
        requestObj: {
            url,
            cache: true,
            merchantId
        }
    })
}

export function getRefundsByReference(merchantId, reference) {
    let url = `${PAYMENTS_BASE}?reference=${reference}`
    return runRequest({
        startAction: () => listRefundsStart(url),
        successAction: (transactions) => {
            return listRefundsSuccess(transactions)
        },
        errorAction: listSettledError,
        requestObj: {
            url,
            cache: true,
            merchantId
        }
    })
}

function listSettledError() {
    return {
        type: t.LIST_SETTLED_ERROR
    }
}

// Operations modal

export const showOperationsModal = (merchantId, action, orderId) => {
    const url = `${PAYMENTS_BASE}/${orderId}/?with_order_information=true`
    return runRequest({
        startAction: () => { return { type: t.SHOW_OPERATIONS_MODAL } },
        successAction: (data) => { return setOperationModalOrder(action, orderId, data) },
        errorAction: operationsModalError,
        requestObj: {
            url,
            merchantId,
            headers: PAYMENTS_BASE_HEADERS
        }
    })
}

const setOperationModalOrder = (action, orderId, order) => {
    return emitAndUpdateInList({
        type: t.SET_OPERATIONS_MODAL_STATE,
        payload: {
            id: orderId,
            order,
            action // only used by the set modal state reducer
        }
    })
}

export const operationsModalError = () => {
    return {
        type: t.OPERATIONS_MODAL_ERROR
    }
}

export const hideOperationsModal = () => {
    return {
        type: t.HIDE_OPERATIONS_MODAL
    }
}

export const captureOrderStart = () => {
    return {
        type: t.CAPTURE_ORDER_START
    }
}

export const captureOrderSuccess = (orderId, order, operationId) => {
    order = setPaymentMethod(order)
    const operation = order.operations.find(o => o.id === operationId.toString())
    return emitAndUpdateInList({
        type: t.CAPTURE_ORDER_SUCCESS,
        payload: {
            id: orderId,
            order,
            success: !!operation.success
        }
    })
}


export const captureOrder = (merchantId, captureBody, orderId, operationId) => {
    const url = `${PAYMENTS_BASE}/${orderId}/capture/${operationId}`
    return runRequest({
        startAction: captureOrderStart,
        successAction: (order) => captureOrderSuccess(orderId, order, operationId),
        errorAction: operationsModalError,
        requestObj: {
            url,
            headers: PAYMENTS_BASE_HEADERS,
            method: 'POST',
            body: JSON.stringify(captureBody),
            merchantId
        }
    })
}

export const refundOrderStart = () => {
    return {
        type: t.REFUND_ORDER_START
    }
}

export const refundInvoiceStart = () => {
    return {
        type: t.REFUND_INVOICE_START
    }
}

export const refundOrderSuccess = (orderId, order, operationId) => {
    order = setPaymentMethod(order)
    const operation = order.operations.find(o => o.id === operationId.toString())
    return emitAndUpdateInList({
        type: t.REFUND_ORDER_SUCCESS,
        payload: {
            id: orderId,
            order,
            success: !!operation.success
        }
    })
}

export const refundInvoiceSuccess = (orderId, order, operationId) => {
    order = setPaymentMethod(order)
    const operation = order.operations.find(o => o.id === operationId.toString())
    return emitAndUpdateInList({
        type: t.REFUND_INVOICE_SUCCESS,
        payload: {
            id: orderId,
            order,
            success: !!operation.success
        }
    })
}

export const refundOrder = (merchantId, refundBody, orderId, operationCount) => {
    const url = `${PAYMENTS_BASE}/${orderId}/refund/${operationCount}`
    return runRequest({
        startAction: refundOrderStart,
        successAction: (order) => refundOrderSuccess(orderId, order, operationCount),
        errorAction: operationsModalError,
        requestObj: {
            url,
            headers: PAYMENTS_BASE_HEADERS,
            method: 'POST',
            body: JSON.stringify(refundBody),
            merchantId
        }
    })
}

export const refundInvoice = (merchantId, refundBody, orderId, operationCount) => {
    const url = `${PAYMENTS_BASE}/${orderId}/refund/${operationCount}`
    return runRequest({
        startAction: refundInvoiceStart,
        successAction: (order) => refundInvoiceSuccess(orderId, order, operationCount),
        errorAction: operationsModalError,
        requestObj: {
            url,
            headers: PAYMENTS_BASE_HEADERS,
            method: 'POST',
            body: JSON.stringify(refundBody),
            merchantId
        }
    })
}

// Cancel modal

export const showCancelModal = (merchantId, orderId) => {
    const url = `${PAYMENTS_BASE}/${orderId}/`
    return runRequest({
        startAction: () => { return { type: t.SHOW_CANCEL_ORDER_MODAL } },
        successAction: (data) => { return setCancelModalOrder(orderId, data) },
        errorAction: cancelOrderError,
        requestObj: {
            url,
            merchantId,
            headers: PAYMENTS_BASE_HEADERS
        }
    })
}

export const hideCancelModal = () => {
    return {
        type: t.HIDE_CANCEL_ORDER_MODAL
    }
}

const setCancelModalOrder = (orderId, order) => {
    return emitAndUpdateInList({
        type: t.SET_CANCEL_ORDER_MODAL_STATE,
        payload: {
            id: orderId,
            order
        }
    })
}


export const cancelOrder = (merchantId, orderId) => {
    const url = `${PAYMENTS_BASE}/${orderId}/cancel/`
    return runRequest({
        startAction: cancelOrderStart,
        successAction: (order) => cancelOrderSuccess(orderId, order),
        errorAction: cancelOrderError,
        requestObj: {
            url,
            headers: PAYMENTS_BASE_HEADERS,
            method: 'POST',
            body: JSON.stringify({}),
            merchantId
        }
    })
}

const cancelOrderSuccess = (orderId, order) => {
    return emitAndUpdateInList({
        type: t.CANCEL_ORDER_SUCCESS,
        payload: {
            id: orderId,
            order: order
        }
    })
}

const cancelOrderStart = () => {
    return {
        type: t.CANCEL_ORDER_START
    }
}

const cancelOrderError = () => {
    return {
        type: t.CANCEL_ORDER_ERROR
    }
}

export const setRefundInvoiceExponent = (exponent) => {
    return {
        type: t.REFUND_INVOICE_SET_EXPONENT,
        payload: {
            exponent
        }
    }
}

export const setRefundInvoiceChecked = (key, value) => {
    return {
        type: t.REFUND_INVOICE_SET_CHECKED,
        payload: {
            key,
            value
        }
    }
}

export const setRefundInvoiceAmount = (key, value) => {
    return (dispatch) => {
        dispatch({
            type: t.REFUND_INVOICE_SET_AMOUNT,
            payload: {
                key,
                value
            }
        })
        dispatch({
            type: t.REFUND_INVOICE_CALCULATE_TOTAL
        })
    }
}
export const setRefundInvoiceAmountValid = (key, value) => {
    return {
        type: t.REFUND_INVOICE_SET_AMOUNT_VALID,
        payload: {
            key,
            value
        }
    }
}

export const setRefundInvoiceValid = (valid) => {
    return {
        type: t.REFUND_INVOICE_SET_VALID,
        payload: {
            valid
        }
    }
}

export const clearRefundInvoiceState = () => {
    return {
        type: t.REFUND_INVOICE_CLEAR_STATE
    }
}

// Helper function

const emitAndUpdateInList = (action) => {
    // curry, we return a function that dispactes the action in the
    // argument and then dispaches a LIST_UPDATE_ITEM action with the same
    // payload that we have in the action in the argument
    return (dispatch) => {
        let copy = Object.assign({}, action.payload.order)
        action.payload.order.originalOrder = copy
        dispatch(action)
        if (!action.payload || !action.payload.id || !action.payload.order) {
            throw ('Unable to update list, invalid action payload', action.payload)
        }
        dispatch({
            type: t.LIST_UPDATE_ITEM,
            payload: action.payload
        })
    }
}

export const removeCurrentPayment = () => {
    return {
        type: t.REMOVE_CURRENT_PAYMENT
    }
}
