import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import uuid from 'uuid'

import GraphWrapper from '../components/graphWrapper'
import OverviewStatistics from '../components/yearlyStatistics'
import DashboardDatePicker from '../components/dashboardDatePicker'

import * as actions from '../actions'
import { getMerchant } from '../../reports/helpers'
import * as helpers from '../helpers'
import PayoutWidget from '../components/payoutWidget'
import LoanWidget from '../components/loanWidget'
import Spinner from '../../common/components/spinner'
import { DATE_NAVIGATION, DEFAULT_DATE_FORMAT, TEXT, WELCOME } from '../constants'
import UserGuide from '../components/userGuideComponent'

import config from '../../config'
import { REDIRECT_LINK } from '../../config/constants'

import { Map } from 'immutable'
import { ROLES } from '../../merchant/constants'
import RoleToggle from '../../common/containers/roleToggle'
import { channelNameIsValid } from '../../common/helpers/channelNameIsValid'
import { determineChannelOptions } from '../helpers/channels'

import DashboardFilters from '../components/dashboardFilters'

import ErrorBoundary from '../../common/components/errorBoundary'
import * as userActions from '../../user/actions'
import { USER_PREFERENCE } from '../../common/constants'


class Dashboard extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            channels: {
                opened: false,
                selected: null
            },
            source_merchant_ids: {
                opened: false,
                selected: null
            },
            currency: {
                opened: false,
                selected: null
            },
            date: {
                yearly: false,
                today: moment(new Date()),
                selected: moment(new Date()),
                disabled: true
            }
        }

        this.fetchPayouts()
        this.fetchLoanCredit()
    }

    render() {
        const { formatMessage } = this.props.intl
        const _determineMidsForChannel = (channel) => {
            switch (channel) {
                case 'acquiring':
                    return elixirIds
                case 'online':
                    return onlineIds
                case 'instore':
                    return instoreIds
                default:
                    return []
            }
        }

        if (!this.props.dashboard) {
            return null
        }

        const aggregates = this.props.dashboard.get('data')
        const count = this.props.dashboard.get('totalCount')
        const sales = this.props.dashboard.get('totalAmount')
        const exponent = this.props.dashboard.get('exponent')
        const currency = this.props.dashboard.get('currency')

        let merchantObject = getMerchant(this.props.match.params.merchantId, this.props.merchant.get('merchants'))
        const isLoanEligible = merchantObject && merchantObject.extra_data.loan_eligible
        const availableChannels = merchantObject.extra_data.sales_types

        const channelOptions = determineChannelOptions(availableChannels) // ['instore']
        const { elixirIds, onlineIds, instoreIds } = helpers.getAvailableSystemIds(channelOptions, merchantObject)
        const midOptions = _determineMidsForChannel(this.state.channels.selected)
        const currencyOptions = helpers.getCurrencyForChannel(merchantObject, this.state.channels.selected)
        const firstLoad = this.props.isFetching && !aggregates.length
        const fetchingOverlayCssClass = firstLoad ? 'overlay hide' : 'overlay'

        const filterSelectedValue = {
            isYearly: this.state.date.yearly,
            currency: this.state.currency.selected,
            sourceMerchantId: this.state.source_merchant_ids.selected,
            channel: this.state.channels.selected
        }
        const filterParams = {
            channelOptions,
            midOptions,
            currencyOptions,
            filterSelectedValue,
            channelSeleted: this.state.channels.selected,
            isYearly: this.state.date.yearly,
            sourceMerchantIdsSelected: this.state.source_merchant_ids.selected,
            currencySelected: this.state.currency.selected,
            addFilter: this.addFilter.bind(this),
            selectDateFilter: this.selectDateFilter.bind(this)
        }

        let yearlyAmount = this.props.payouts.get('yearly')

        return (
            <>
                <div className="dashboard-wrapper">
                    <div className={fetchingOverlayCssClass}>
                        {firstLoad && <Spinner waiting={true} />}
                        <ErrorBoundary>
                            <div className="sub-menu-container">
                                <div className="sub-menu">
                                    <DashboardDatePicker selectedDate={this.state.date.selected}
                                        isDisabled={this.state.date.disabled}
                                        isYearly={this.state.date.yearly}
                                        handleDateChange={this.handleDateChange.bind(this)} />
                                    <DashboardFilters {...filterParams} />
                                </div>
                            </div>
                        </ErrorBoundary>
                        <ErrorBoundary>
                            <OverviewStatistics count={count}
                                sales={sales}
                                exponent={exponent}
                                currency={this.state.currency.selected}
                                formatMessage={formatMessage} />
                        </ErrorBoundary>
                        <ErrorBoundary>
                            <GraphWrapper
                                locale={this.props.localization.get('localeCode')}
                                dataset={aggregates}
                                isAggregatePeriodYearly={this.state.date.yearly}
                                exponent={exponent}
                                currency={currency}
                                env={this.props.env}
                                formatMessage={formatMessage}
                            />
                        </ErrorBoundary>
                        <ErrorBoundary>
                            <div className="sales-link">
                                <div onClick={() => this.redirectToSales()}>{formatMessage(TEXT['sales-link'])}</div>
                            </div>
                            <div className="divider"></div>
                            {yearlyAmount && merchantObject.extra_data.settlement_currencies.map(currency => {
                                if (yearlyAmount[currency] && yearlyAmount[currency].amount == 0) return null
                                return (
                                    <div className={`payouts-container-dashboard ${isLoanEligible ? 'extend' : ''}`} key={uuid.v4()}>
                                        <PayoutWidget
                                            redirectLink={() => this.redirectToPayout()}
                                            latest={Map(this.props.payouts.get('latest'))}
                                            monthly={Map(this.props.payouts.get('monthly'))}
                                            yearly={Map(this.props.payouts.get('yearly'))}
                                            isFetching={this.props.payouts.get('isFetching')}
                                            formatMessage={formatMessage}
                                            currency={currency}
                                        />
                                    </div>
                                )
                            }
                            )}
                            {isLoanEligible &&
                                <RoleToggle allow={[ROLES.ADMIN, ROLES.OWNER]}>
                                    <div className="loan-container-dashboard extend">
                                        <LoanWidget formatMessage={formatMessage}
                                            remainingDebt={this.props.loan.get('remaining_debt')}
                                            availableCredit={this.props.loan.get('available_credit')}
                                            isFetching={this.props.loan.get('isFetching')}
                                            hasActiveLoan={this.props.loan.get('hasActiveLoan')}
                                            redirectLink={() => this.redirectToLoan()} />
                                    </div>
                                </RoleToggle>
                            }
                        </ErrorBoundary>
                    </div>
                </div>
                {
                    this.props.path[REDIRECT_LINK] === WELCOME &&
                    <UserGuide isLoanEligible={isLoanEligible} merchantObject={merchantObject} onClose={this.onCloseUserGuide.bind(this)} formatMessage={formatMessage} />}
            </>
        )
    }

    setUserPreference(merchantID, merchantSettings) {
        this.props.dispatch(
            userActions.setUserPreference({
                value: merchantSettings,
                type: merchantID
            })
        )
    }

    componentDidMount() {
        let merchantSettings = this.props.merchantSettings || {}
        const merchantID = this.props.match.params.merchantId
        let selectedDashBoardChannel

        if (merchantSettings && merchantSettings['dashboardChannel']) {
            selectedDashBoardChannel = merchantSettings['dashboardChannel']
        }

        let merchantObject = getMerchant(merchantID, this.props.merchant.get('merchants'))
        const availableChannels = determineChannelOptions(merchantObject.extra_data.sales_types)

        if (selectedDashBoardChannel && channelNameIsValid(selectedDashBoardChannel, availableChannels)) {
            this.initDashboard(selectedDashBoardChannel)
        }
        else {
            if (selectedDashBoardChannel && !channelNameIsValid(selectedDashBoardChannel, availableChannels)) {
                // Remove incorrect key from local storage
                delete merchantSettings['dashboardChannel']

                this.setUserPreference(merchantID, merchantSettings)
            }

            if (availableChannels && availableChannels[0]) this.initDashboard(availableChannels[0])
        }
    }

    //end of React life cycle


    addFilter(filters) {
        let updatedState = this.state
        const merchantID = this.props.match.params.merchantId
        let merchantSettings = this.props.merchantSettings || {}

        filters.forEach(filter => {
            updatedState[filter.type].selected = filter.value

            // Persist channel selection in local storage
            if (filter.type === 'channels') {
                const channel = filter.value

                merchantSettings.dashboardChannel = channel
            }

            // Persist currency selection in local storage
            if (filter.type === 'currency') {
                const currency = filter.value

                merchantSettings.dashboardCurrency = currency
            }

            if (filter.type === 'channels' || filter.type === 'currency') {
                this.setUserPreference(merchantID, merchantSettings)
            }

        })

        this.setState(updatedState)
        this.props.dispatch(actions.listPaymentAggregates(
            this.props.match.params.merchantId,
            this.state.channels.selected,
            this.state.date.yearly,
            this.state.date.selected.format(DEFAULT_DATE_FORMAT),
            this.getActiveFilters()
        ))

        this.closeAllDropdowns()
    }


    fetchPayouts() {
        this.props.dispatch(actions.listPayoutAggregates(this.props.match.params.merchantId))
    }

    fetchLoanCredit() {
        let merchant = getMerchant(this.props.match.params.merchantId, this.props.merchant.get('merchants'))
        const roleHasPermission = merchant && [ROLES.ADMIN, ROLES.OWNER].includes(merchant.loggedInUserRole)
        if (!merchant || !merchant.extra_data || !merchant.extra_data.loan_eligible || !roleHasPermission) return

        this.props.dispatch(actions.listCredit(this.props.match.params.merchantId))
    }

    isDateInFuture(date, isYear) {
        isYear = isYear || this.state.date.yearly
        return this.state.date.today.format(isYear ? 'YYYY' : 'YYYY-MM') <= date.format('YYYY-MM')
    }

    handleDateChange(navigateTo) {
        let updatedState = this.state
        if (navigateTo === DATE_NAVIGATION.prev) updatedState['date']['selected'].subtract(1, this.state.date.yearly ? 'years' : 'months')
        else if (navigateTo === DATE_NAVIGATION.next) updatedState['date']['selected'].add(1, this.state.date.yearly ? 'years' : 'months')

        this.props.dispatch(actions.listPaymentAggregates(
            this.props.match.params.merchantId,
            this.state.channels.selected,
            this.state.date.yearly,
            updatedState['date']['selected'].format(DEFAULT_DATE_FORMAT),
            this.getActiveFilters()
        ))

        updatedState['date']['disabled'] = this.isDateInFuture(updatedState['date']['selected']) ? true : false

        this.setState(updatedState)
    }

    getCurrency(channel, merchantObject) {
        const merchantID = this.props.match.params.merchantId
        let merchantSettings = this.props.merchantSettings || {}
        let currencySelectedByUser

        if (merchantSettings && merchantSettings['dashboardCurrency']) {
            currencySelectedByUser = merchantSettings['dashboardCurrency']
        }
        const availableCurrencies = helpers.getCurrencyForChannel(merchantObject, channel)
        const selectedCurrencyIsValid = availableCurrencies.includes(currencySelectedByUser)

        if (currencySelectedByUser && selectedCurrencyIsValid) {
            return currencySelectedByUser
        } else {

            if (currencySelectedByUser && !selectedCurrencyIsValid) {
                delete merchantSettings['dashboardCurrency']
                this.setUserPreference(merchantID, merchantSettings)
            }
            return helpers.getCurrencyForChannel(merchantObject, channel)[0]
        }

    }

    initDashboard(preSelectChannel) {
        let merchantObject = getMerchant(this.props.match.params.merchantId, this.props.merchant.get('merchants'))
        // TODO: Set proper rules which currency etc should be "default"

        const channel = preSelectChannel ? preSelectChannel : determineChannelOptions(merchantObject.extra_data.sales_types)[0]

        const currency = this.getCurrency(channel, merchantObject)

        let updatedState = this.state
        updatedState['channels'].selected = channel
        updatedState['currency'].selected = currency

        moment.locale(this.props.localization.toJS().localeCode)
        updatedState.date = {
            yearly: false,
            today: moment(new Date()),
            selected: moment(new Date()),
            disabled: true
        }

        let filters = {}

        if (currency) {
            filters.currency = currency
        }


        this.setState(updatedState)

        this.props.dispatch(actions.listPaymentAggregates(
            this.props.match.params.merchantId,
            channel,
            this.state.date.yearly,
            this.state.date.selected.format(DEFAULT_DATE_FORMAT),
            filters
        ))
    }

    getActiveFilters(filters = {}) {
        const selectedMerchantIds = this.state.source_merchant_ids.selected
        if (selectedMerchantIds && selectedMerchantIds.length > 0) {
            filters.source_merchant_ids = selectedMerchantIds.map(item => {
                if (item.id) return item.id
                else return item.text
            })
        }

        if (this.state.currency.selected) {
            filters.currency = this.state.currency.selected
        }

        return filters
    }

    closeAllDropdowns() {
        let updatedState = this.state
        Object.keys(this.state).map((filterType) => {
            updatedState[filterType].opened = false
        })

        this.setState(updatedState)
    }

    selectDateFilter(e) {
        const isYear = e.target.value === 'year'
        let updatedState = this.state
        updatedState['date']['yearly'] = isYear
        updatedState['date']['disabled'] = this.isDateInFuture(updatedState['date']['selected'], isYear) ? true : false

        const month = updatedState['date']['today'].month()
        updatedState['date']['selected'].month(month)

        this.setState(updatedState)

        this.props.dispatch(
            actions.listPaymentAggregates(
                this.props.match.params.merchantId,
                this.state.channels.selected,
                isYear,
                this.state['date']['selected'].format(isYear ? 'YYYY' : DEFAULT_DATE_FORMAT),
                this.getActiveFilters()
            )
        )
    }

    redirectToPayout() {
        return this.props.history.push(`/merchants/${this.props.match.params.merchantId}/payouts`)
    }

    redirectToLoan() {
        return this.props.history.push(`/merchants/${this.props.match.params.merchantId}/loans`)
    }

    redirectToSales() {
        this.props.history.push(`/merchants/${this.props.match.params.merchantId}/sales`)
    }

    onCloseUserGuide() {
        let deepLinkPath = this.props.path
        deepLinkPath[REDIRECT_LINK] = ''
        this.props.dispatch(config.actions.setDeepLinkPath({ deepLinkPath }))
    }
}

const mapStateToProps = (state) => {
    const activeMerchant = state.getIn(['merchant', 'activeMerchant'])

    return {
        config: state.get('config'),
        consent: state.getIn([activeMerchant, 'loans', 'givenConsent']),
        dashboard: state.getIn([activeMerchant, 'dashboard']),
        merchantSettings: state.get('user').getIn(['preference', activeMerchant]),
        isFetching: state.getIn([activeMerchant, 'dashboard', 'isFetching']),
        payouts: state.getIn([activeMerchant, 'dashboard', 'payouts']),
        loan: state.getIn([activeMerchant, 'dashboard', 'loan']),
        merchant: state.get('merchant'),
        localization: state.get('localization'),
        path: state.getIn(['config', 'deepLinkPath']),
        env: state.getIn(['config', 'environment']),
        reportsFeatureHighLightVisited: state.get('user').get('preference').get(USER_PREFERENCE.REPORTS_FEATURE_HIGHLIGHT_POP_VISITED)
    }
}

export default connect(mapStateToProps)(withRouter(injectIntl(Dashboard)))
