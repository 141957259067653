import React, { useState, useEffect, useMemo, useRef, Fragment } from 'react'
import { injectIntl } from 'react-intl'
import { DayPicker } from 'react-day-picker'
import { FILTER_BUTTON_TEXT } from '../../sales/sales/constants'
import { CUSTOM_PERIOD, DATE_PICKER } from '../translations'
import {
    addDays,
    differenceInCalendarDays,
    eachDayOfInterval,
    format,
    isEqual,
    lastDayOfMonth,
    max,
    min,
    previousMonday,
    previousSunday,
    startOfMonth,
    subDays,
    subMonths
} from 'date-fns'
import { sv, nb, da, fi, enGB, nl, fr, it, de } from 'date-fns/locale'
import './datePickerDropdown.scss'
import { Icon } from '@frontrunners/ui-components'
import { getTodayMidnight } from '../dateHelpers'
import { isMobile as isMobileGlobal } from '../helpers/isMobile'
import * as dateHelpers from '../../common/dateHelpers'

const isRangesEqual = (left, right) => {
    return !left && !right
        || left && right
        && isEqual(left.from, right.from)
        && isEqual(left.to, right.to)
}

const DatePickerDropdown = ({
    defaultRange=null,
    minSelectableDate,
    maxSelectableDate,
    maxDayRange,
    onDatesChange,
    localeCode,
    isOpen: overrideIsOpen=null,
    setIsOpen: overrideSetIsOpen=null,
    isMobile: isMobileOverride=null,
    intl
}) => {
    if (overrideIsOpen !== null && overrideSetIsOpen === null || overrideIsOpen === null && overrideSetIsOpen !== null) {
        throw new TypeError('isOpen and setIsOpen must either both be provided or neither be provided')
    }
    if (defaultRange && !(defaultRange.from && defaultRange.to)) {
        throw new TypeError('defaultRange must have properties "to" and "from"')
    }

    const isMobile = isMobileOverride || isMobileGlobal()
    const { formatMessage } = intl
    const [range, setRange] = useState(defaultRange)
    const [lastAppliedRange, setLastAppliedRange] = useState(defaultRange)
    const [defaultIsOpen, defaultSetIsOpen] = useState(false)

    const isOpen = overrideIsOpen || defaultIsOpen
    const setIsOpen = overrideSetIsOpen || defaultSetIsOpen

    const toggleButtonRef = useRef(null)
    const dropdownRef = useRef(null)

    useEffect(() => {
        if (range && !defaultRange) {
            // This is a dirty hack; it allows the default range to be cleared from outside this component (e.g. from "clear all filters" buttons).
            // It's easier to do this than to try to derive a good key in the parent component.
            setRange(null)
            setLastAppliedRange(null)
        }

        if (defaultRange) {
            setRange(defaultRange)
            setLastAppliedRange(defaultRange)
        }
    }, [defaultRange])

    useEffect(() => {
        document.addEventListener('click', handleClickOnToggleOrOutside, true)
        return () => {
            document.removeEventListener('click', handleClickOnToggleOrOutside, true)
        }
    })

    const containsToggleButton = (event) => toggleButtonRef.current && toggleButtonRef.current.contains(event.target)

    const containsDropdown = (event) => dropdownRef.current && dropdownRef.current.contains(event.target)

    /**
     * We need to handle clicks on the toggle button and outside the component separately from clicking the apply button.
     *
     * A simple onClick does not work on the toggle button as it doesn't register when clicking after clearing a selection or
     * or making the first selection, most likely related to some rendering quirk.
     */
    const handleClickOnToggleOrOutside = (event) => {
        if (isOpen && !containsDropdown(event)) {
            applySelection()
        } else if (!isOpen && containsToggleButton(event)) {
            setIsOpen(true)
        }
    }

    const applySelection = () => {
        const currentRange = range
            ? dateHelpers.createDateRange(range.from, range.to || range.from)
            : null

        if (!isRangesEqual(currentRange, lastAppliedRange)) {
            onDatesChange(currentRange)
        }

        setRange(currentRange)
        setLastAppliedRange(currentRange)
        setIsOpen(false)
    }

    const rangeIsTooLarge = range && (
        range.from && !range.to
        || Math.abs(differenceInCalendarDays(range.from, range.to)) + 1 > maxDayRange
    )

    const dayRangeLimitMessage = maxDayRange && (!range || rangeIsTooLarge)
        ? formatMessage(DATE_PICKER['time_limit'])
        : null

    return (
        <div className="date-picker-wrapper">
            <div className="date-picker-button" data-testid="date-picker-button" ref={toggleButtonRef}>
                { lastAppliedRange
                    ? <RangeSummaryButton range={lastAppliedRange} />
                    : <Button className={`date-picker-toggler ${isOpen ? 'open': ''}`} label={formatMessage(FILTER_BUTTON_TEXT['dates'])} showArrow/>
                }
            </div>
            {isOpen &&
                <div className={`date-picker-dropdown ${ isMobile ? 'mobile' : ''}`} ref={dropdownRef}>
                    <DateRangeSelector
                        range={range}
                        setRange={setRange}
                        localeCode={localeCode}
                        minSelectableDate={minSelectableDate}
                        maxSelectableDate={maxSelectableDate}
                        formatMessage={formatMessage}
                        maxDayRange={maxDayRange}
                        isMobile={isMobile}
                    />
                    { dayRangeLimitMessage &&
                        <div className="set-time-period">{dayRangeLimitMessage}</div>
                    }
                    <div className="action-selectors">
                        { range &&
                            <div className="action-selector" onClick={() => setRange(null)}>
                                {formatMessage(FILTER_BUTTON_TEXT['clear'])}
                            </div>
                        }
                        <div className="action-selector" onClick={applySelection}>
                            {formatMessage(FILTER_BUTTON_TEXT['apply'])}
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

const DateRangeSelector = ({ range, setRange, localeCode, minSelectableDate, maxSelectableDate, formatMessage, maxDayRange, isMobile }) => {
    const today = getTodayMidnight()
    const getDefaultMonth = () => {
        if (range) {
            return range.from
        } else if (isMobile) {
            return today
        } else {
            return subMonths(today, 1)
        }
    }

    const [selectedMonth, setSelectedMonth] = useState(getDefaultMonth())
    const locale = useMemo(() => getLocale(localeCode))

    const handleDayClick = (day) => {
        if (!range || (range.from && range.to)) {
            setRange(dateHelpers.createDateRange(day, null))
        } else if (range.from && !range.to) {
            const from = min([range.from, day])
            const to = max([range.from, day])
            setRange(dateHelpers.createDateRange(from, to))
        }
    }

    const selectYesterday = () => {
        const yesterday = subDays(today, 1)
        setRange(dateHelpers.createDateRange(yesterday, yesterday))
        setSelectedMonth(yesterday)
    }

    const selectLastWeek = () => {
        const lastSunday = previousSunday(today)
        const mondayLastWeek = previousMonday(lastSunday)
        setRange(dateHelpers.createDateRange(mondayLastWeek, lastSunday))
        setSelectedMonth(mondayLastWeek)
    }

    const selectLastMonth = () => {
        const lastMonth = subMonths(today, 1)
        const firstDayInLastMonth = startOfMonth(lastMonth)
        const lastDayInLastMonth = lastDayOfMonth(lastMonth)
        setRange(dateHelpers.createDateRange(firstDayInLastMonth, lastDayInLastMonth))
        setSelectedMonth(firstDayInLastMonth)
    }

    // for the navbar to be visible we need to ensure that the max date is at least a month away from the min date
    const maxSelectableDateAdjustedForNavbarVisibility = addDays(maxSelectableDate, 31)
    const daysInNavbarVisibilityAdjustmentRange = eachDayOfInterval({
        start: addDays(maxSelectableDate, 1),
        end: maxSelectableDateAdjustedForNavbarVisibility
    })

    return (
        <Fragment>
            <DayPicker
                numberOfMonths={isMobile ? 1 : 2}
                month={selectedMonth}
                onMonthChange={setSelectedMonth}
                mode="range"
                selected={range}
                onDayClick={handleDayClick}
                locale={locale}
                fromDate={minSelectableDate}
                toDate={maxSelectableDateAdjustedForNavbarVisibility}
                disabled={daysInNavbarVisibilityAdjustmentRange}
                max={maxDayRange}
            />
            <div className="quick-selectors">
                <Button label={formatMessage(CUSTOM_PERIOD['yesterday'])} onClick={selectYesterday} />
                <Button label={formatMessage(CUSTOM_PERIOD['last_week'])} onClick={selectLastWeek} />
                <Button label={formatMessage(CUSTOM_PERIOD['last_month'])} onClick={selectLastMonth} />
            </div>
        </Fragment>
    )
}

const RangeSummaryButton = ({ range }) => {
    const fromDateString = format(range.from, 'yyyy-MM-dd')
    const toDateString = format(range.to, 'yyyy-MM-dd')

    return (
        <div className="date-picker-range">
            <div className={'date-picker-range-date'} data-testid={'date-picker-range-date-start'}>
                {fromDateString}
            </div>
            <div className={'date-picker-range-date'} data-testid={'date-picker-range-date-end'}>
                {toDateString}
            </div>
        </div>
    )
}

const Button = ({ className, label, onClick, showArrow }) => {
    return (
        <button className={className} onClick={onClick}>
            { label }
            { showArrow && <Icon iconName="arrowDown" /> }
        </button>
    )
}

const getLocale = ( localeCode ) => {
    return {
        'de': de,
        'en': enGB,
        'nl-NL': nl,
        'da': da,
        'fr': fr,
        'it': it,
        'nb': nb,
        'fi': fi,
        'sv': sv
    }[localeCode]
}

export default injectIntl(DatePickerDropdown)
