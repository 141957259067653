import React, { Component } from 'react'
import { connect } from 'react-redux'
import { injectIntl, FormattedDate, FormattedMessage } from 'react-intl'
import * as actions from '../actions'
import Spinner from '../../common/components/spinner'
import InvoiceDetailComponent from '../components/invoiceDetailComponent'
import { TEXT } from '../constants'
import { trackUserActionEvent } from '../../common/helpers/mixpanelTrackers'
import { ApplicationContext } from '../../common/reactContext'


class InvoiceReportItem extends Component {
    static contextType = ApplicationContext

    fetchInvoice(key, report, format = this.props.invoice.get('reportFormat')) {
        // fetch Invoice:
        //  - for 'json' format, it will get the invoice dataset in json
        //  - for other format, it will get a download link and execute download

        const dispatch = this.props.dispatch
        const locale = this.props.localization.get('localeCode')
        const downloadLink = this.refs['download-' + key]

        trackUserActionEvent('Download Invoice', window.location, this.context, {format: format})

        dispatch(actions.requestInvoiceReport(
            this.props.merchantId,
            report.period,
            format,
            report.display_name,
            locale,
            downloadLink
        ))
    }

    renderReportItem(report, key) {
        const { formatMessage } = this.props.intl
        const showDetail = ( report.display_name === this.props.expandedInvoice )
        const expandText = showDetail ? formatMessage(TEXT['hide-details']) : formatMessage(TEXT['show-details'])
        const expandClass = showDetail ? 'invoice-item--expand' : ''
        const activeReport = this.props.invoice.get('activeReport')

        return (
            <div className={`invoice-item ${expandClass}`}>
                <div className="invoice-item-wrapper">
                    <div className="invoice-item__overview">
                        <div className="invoice-report-list-month">
                            <div className="invoice-report-item">
                                <FormattedDate value={report.period} month="long" />
                            </div>
                        </div>
                        {activeReport.get('isFetching') && activeReport.get('serialNumber') === report.display_name ?
                            <div className="download-invoice-spinner"><Spinner waiting={true} /></div> : (
                                <div className="invoice-report-download" onClick={() => { this.fetchInvoice(key, report) }}>
                                    <div className="icon-invoice-pdf"></div>
                                    <FormattedMessage
                                        id="invoice-report-download-link-text"
                                        defaultMessage={'Download invoice'}
                                        description="Clickable text to start downloading a invoice report."
                                    />
                                </div>
                            )
                        }
                        <div className="invoice-report-list-expander" onClick={this.fetchAndExpandInvoice.bind(this, showDetail, key)} >{expandText}</div>
                    </div>
                    <div>
                        {showDetail && <InvoiceDetailComponent activeReportJson={this.props.invoice.get('activeReportJson')} report={report}/>}
                    </div>
                </div>

            </div>
        )
    }

    fetchAndExpandInvoice(showDetail, key) {
        const { report } = this.props
        if (!showDetail) this.fetchInvoice(key, report, 'json')
        this.props.expandInvoice(report.display_name)
    }

    render() {
        const report = this.props.report
        const key = `${report.period.valueOf().toString()}-${report.invoicing_entity_code}-${report.country_code}`
        const fileName = `${report.period}-invoice-report-${report.invoicing_entity_code}-${report.country_code}`
        return (
            <div className="report-month-file" key={key}>
                {this.renderReportItem(report, key)}
                <a href="" className="hide" ref={'download-' + key} download={fileName}></a>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        localization: state.get('localization')
    }
}

export default connect(mapStateToProps)(injectIntl(InvoiceReportItem))
