import { flattenFilters, addDayToISOstring } from '../../sales/sales/helpers'
import { createSearch } from '../../common/locationHelpers'

import moment from 'moment'


const convert = (filter, activeView) => {
    const { from_datetime, to_datetime, ...rest } = filter
    filter = { from_date: from_datetime, to_date: to_datetime, ...rest }

    if (['instore', 'online'].includes(activeView)) {
        const { source_merchant_id, ...rest } = filter
        if (source_merchant_id) filter = { source_merchant_ids: source_merchant_id, ...rest }
    }

    //work around for backoffice-reports parameter conflict
    if (activeView === 'acquiring' && filter.type) {
        filter.payment_category = filter.type
        delete filter.type
    }

    let flattenedFilters = flattenFilters(filter)

    if (flattenedFilters.to_date) {
        flattenedFilters.to_date = addDayToISOstring(flattenedFilters.to_date)
    }
    flattenedFilters.to_date = moment(flattenedFilters.to_date).format('YYYY-MM-DD')
    flattenedFilters.from_date = moment(flattenedFilters.from_date).format('YYYY-MM-DD')

    const mergedSearch = Object.assign({}, {}, flattenedFilters)
    const queries = createSearch(mergedSearch)
    return queries
}


export default convert
