import { MIXPANEL_ALWAYS_TRACK_EVENTS } from '../../merchant/constants'

function mixpanelMiddleware(mixpanel) {
    if (!mixpanel || !mixpanel.track) {
        throw new TypeError('You must provide a mixpanel client instance.')
    }

    return function () {
        return function (next) {
            return function (action) {
                if (!action.meta || !action.meta.mixpanel || !action.meta.mixpanel.event) {
                    return next(action)
                }
                try {
                    var _action$meta$mixpanel = action.meta.mixpanel
                    var event = _action$meta$mixpanel.event
                    var props = _action$meta$mixpanel.props || {}

                    if (mixpanel.has_opted_out_tracking() && MIXPANEL_ALWAYS_TRACK_EVENTS.includes(event)) {
                        mixpanel.opt_in_tracking()
                        mixpanel.track(event, props)
                        mixpanel.opt_out_tracking()
                    }
                    else {
                        mixpanel.track(event, props)
                    }

                } catch (error) {
                    console.log('mixpanelMiddleware error', error)
                }
                return next(action)
            }
        }
    }
}

export function getMixpanelRoleMapping(user) {
    if (!user) return 'support'
    let roleObj = user.merchant_roles[0]
    return JSON.stringify({
        merchant_id: roleObj.merchant_id,
        role: roleObj.roles[0]
    })
}

export function updateMixpanelProfile(mixpanel, user, merchant){
    // set the users role and email in mixpanel
    if(!user) return

    const role = getMixpanelRoleMapping(user)
    mixpanel.people.union('role', role)
    mixpanel.people.set('org_number', merchant.number)
}

export function registerMixpanelProfile(reduxStore, mixpanel, userProfile){
    let store = reduxStore.getState()
    let config = store.get('config')

    if (config.get('mixpanelToken') && userProfile) {
    // identify user accross sessions
        mixpanel.identify(userProfile.sub)
    // set user data
        mixpanel.people.set({
            mid: userProfile.merchant,
            primary_id: userProfile.sub,
            $distinct_id: userProfile.sub
        })

    // append session data so we can group events per session
    // also append current version of the build
        mixpanel.register({
            application: 'backoffice-frontend',
            session_id: userProfile.sid,
            mid: userProfile.merchant,
            git_head: config.get('gitHead') || null,
            package_version: config.get('packageVersion') || null,
            host: window.location.hostname
        })
    }
}

export default mixpanelMiddleware
