import * as t from './actionTypes'

export const showMerchantDataResponseErrorMessage = () => ({
    type: t.SHOW_REQUEST_FAILED_MESSAGE
})

export const showNoMerchantsErrorMessage = () => ({
    type: t.SHOW_NO_MERCHANTS_MESSAGE
})

export const showErrorPage = () => ({
    type: t.SHOW_ERROR_PAGE
})
