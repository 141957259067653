import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { FormattedMessage, injectIntl } from 'react-intl'
import { setMerchant, notPortalEligible } from '../actions'
import Spinner from '../../common/components/spinner'
import { getSelectableMerchants } from '../helpers'
import { Badge } from '@frontrunners/ui-components'

import { REDIRECT_LINK } from '../../config/constants'

const SelectMerchantContainer = (props) => {

    useEffect(() => {
        const selectableMerchants = getSelectableMerchants(
            props.merchant.get('merchants')
        )
        // redirect to overview if user only has one merchant
        if (selectableMerchants && selectableMerchants.length === 1) {
            const merchantId = selectableMerchants[0].id
            redirectToDeepLink(merchantId)
        }
    }, [props.merchant])

    const path = props.path[REDIRECT_LINK]

    const redirectToDeepLink = (merchantId) => {
        const redirectUrl = path ? `/merchants/${merchantId}/${path}` : `/merchants/${merchantId}`
        props.history.replace(redirectUrl)
    }

    const isFetching = props.merchant && props.merchant.get('isFetching')
    const merchants = props.merchant && props.merchant.get('merchants')
    if (!merchants) return ''
    const selectableMerchants = getSelectableMerchants(merchants)

    return <div className="content main-width select-merchant-wrapper">
        {!isFetching
            && selectableMerchants.length > 1 // required to not momentarily show merchant selector for single-merchant users before redirect
            && <React.Fragment>
                <h2><FormattedMessage
                    id="select-merchant-modal-header"
                    defaultMessage={'Select merchant'}
                    description="Header on modal dropdown for selecting merchant in case there is more than one"
                /></h2>
                <p><FormattedMessage
                    id="select-merchant-modal-text"
                    defaultMessage={'Your login is connected to more than one merchant account. Click on a merchant account to see your sales data and download reports.'}
                    description="Body text on modal dropdown for selecting merchant in case there is more than one"
                /></p>

                <div className="merchant-list-selector">
                {selectableMerchants.map(m => {
                    if (notPortalEligible(m, props.user)) {
                        return (
                            <div key={m.id} className="merchant-selector-merchant-not-portal-eligible">
                            <h3 className="title">
                            {m.name} {m.number ? `(${m.number})` : ''}
                            </h3>
                            <FormattedMessage
                            id="merchant-not-portal-eligible"
                            defaultMessage={'There are too many transactions to generate reports and provide a genuine portal experience.'}
                            description="The merchant is too large detail text"
                            />
                            <br />
                            <FormattedMessage
                            id="merchant-not-portal-eligible-details"
                            defaultMessage={'The Merchant Portal has been disabled for this merchant.'}
                            description="The merchant is too large"
                            />
                            </div>
                        )
                    }
                    return (
                        <Link
                        className="merchant"
                        key={m.id}
                        onClick={() =>{
                            props.dispatch(setMerchant(null))
                        }}
                        to={`/merchants/${m.id}/${path}`}>
                        <h3 className="title">
                        {m.name} {m.number ? `(${m.number})` : <Badge className="orange">
                            <FormattedMessage
                                id="select-merchant-test-account"
                                defaultMessage={'Test account'}
                                description="Test account" />
                            </Badge>
                        }
                        </h3>
                        <RenderAddress merchant={m} />
                        </Link>
                    )
                })}
                </div>
            </React.Fragment>
        }
        {isFetching
            && <div className="text-center">
                <Spinner waiting={isFetching} />
            </div>
        }
        </div>
}

const RenderAddress = (merchant) => {
    const address = merchant.billing_address

    if (!address) {
        return null
    }

    const addressParts = [address.street_address, address.city, address.postal_code, address.country].filter(x => x)

    return <small>
        {addressParts.join(', ')}
    </small>
}

const mapStateToProps = (state) => {
    return {
        merchant: state.get('merchant'),
        user: state.get('user').get('profile'),
        path: state.getIn(['config', 'deepLinkPath'])
    }
}

export default connect(mapStateToProps)(injectIntl(SelectMerchantContainer))
