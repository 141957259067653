import * as types from './actionTypes'
import { REPORTS_BASE, REPORTS_BASE_V2 } from '../common/constants'
import { runRequest } from './../common/requestActionHelper'
import { requestReport } from '../reports/actions'
import moment from 'moment'

export const listAvailableInvoiceStatementsStart = () => {
    return {
        type: types.LIST_AVAILABLE_INVOICE_STATEMENTS_START,
        payload: {
            isFetching: true
        }
    }
}

export const listAvailableInvoiceStatementsSuccess = (response, shouldTrackEvent) => {
    if (shouldTrackEvent) {
        return {
            type: types.LIST_AVAILABLE_INVOICE_STATEMENTS_SUCCESS,
            payload: {
                isFetching: false,
                availableReports: response
            }
        }
    } else {
        return {
            type: types.LIST_AVAILABLE_INVOICE_STATEMENTS_SUCCESS,
            payload: {
                isFetching: false,
                availableReports: response
            }
        }
    }
}


export const listAvailableInvoiceStatementsError = (error, shouldTrackEvent) => {
    if (shouldTrackEvent) {
        return {
            type: types.LIST_AVAILABLE_INVOICE_STATEMENTS_ERROR,
            payload: {
                error
            }
        }
    } else {
        return {
            type: types.LIST_AVAILABLE_INVOICE_STATEMENTS_ERROR,
            payload: {
                error
            }
        }
    }
}

export const listAvailableInvoiceStatements = (merchantId, shouldTrackEvent) => {
    return (dispatch, getState) => { // eslint-disable-line
        return runRequest({
            startAction: listAvailableInvoiceStatementsStart,
            successAction: response => listAvailableInvoiceStatementsSuccess(response, shouldTrackEvent),
            errorAction: response => listAvailableInvoiceStatementsError(response, shouldTrackEvent),
            requestObj: { url: `${REPORTS_BASE}/invoices/serial_numbers/`, merchantId }
        })(dispatch, getState)
    }
}

export const invoicePeriodToTimeSpan = (period) => {
    // from our serial number endpoint, we only get a period like "2017-10".
    // Since our report-service needs a from and to_date we take the period
    // and take the first of that month and the last of that month.

    let timespan = {}
    const date = new Date(period)
    const year = date.getFullYear()
    const month = date.getMonth()

    // Get first day of month
    let fromDate = new Date(year, month, 1)
    fromDate.setTime(fromDate.getTime() - fromDate.getTimezoneOffset() * 60 * 1000)
    timespan.fromDate = moment(fromDate).format('YYYY-MM-DD')

    let toDate = fromDate
    toDate.setMonth(month + 1)

    timespan.toDate = moment(toDate).format('YYYY-MM-DD')
    return timespan

}

const requestInvoiceJsonReportStart = (format, serialNumber) => {
    let type

    if(format==='json'){
       type = types.REQUEST_INVOICE_JSON_START
    }
    else{
        type = types.REQUEST_INVOICE_REPORT_START
    }

    return {
        type,
        payload: { serialNumber }
    }
}

const requestInvoiceReportError = (error, format) => {
    let type

    if(format==='json'){
       type = types.REQUEST_INVOICE_JSON_ERROR
    }
    else{
        type = types.REQUEST_INVOICE_REPORT_ERROR
    }

    return {
        type,
        payload: { error }
    }
}

const requestInvoiceReportSuccess = (data, format, downloadLink) => {
    if (format === 'json') {
        return reqeustInoviceJsonSuccess(data)
    }
    else {
        return requestInvoiceFileSuccess(data, downloadLink)
    }
}

const reqeustInoviceJsonSuccess = (data) => {
    return {
        type: types.REQUEST_INVOICE_JSON_SUCCESS,
        payload: {
            data: JSON.parse(data.payload)
        }
    }
}

const requestInvoiceFileSuccess = (data, downloadLink) => {
        downloadLink.href = data.link
        downloadLink.click()

        return {
            type: types.REQUEST_INVOICE_REPORT_SUCCESS
        }
}

export const requestInvoiceReport = (merchantId, date, format, serialNumber, locale, downloadLink) => {
    return (dispatch, getState) => { // eslint-disable-line
        dispatch(requestInvoiceJsonReportStart(format, serialNumber))
        const { fromDate, toDate } = invoicePeriodToTimeSpan(date)

        const url = `${REPORTS_BASE_V2}/invoices/monthly/${format}?&from_date=${fromDate}&to_date=${toDate}&desired_locale=${locale}`
        const successFn = (data) => {
            dispatch(requestInvoiceReportSuccess(data, format, downloadLink))
        }
        const errorFn = (error) => {
            dispatch(requestInvoiceReportError(error, format))
        }
        requestReport(merchantId, dispatch, getState, url, successFn, errorFn)
    }
}
