export const ALLOWED_DEVELOPER_USERS_FOR_HIDDEN_FEATURES = [
    '83f248eb-9fbe-4026-8143-6b8c7dc88557', // online@backoffice.com
    'c757bf9e-e030-4607-9b04-061ed7b2dc1c', // test3@example.com
    'b8e1908e-bc76-456a-8bfc-53cffe22c755', // froda_historical@example.com
    'b79e2a63-41ca-45c7-a7e7-05ed6c2d3159',
    '18aae0bd-4d43-45e7-852a-fcc782bedfde', // mats.wahlberg@bambora.com
    '6ff60413-7f98-4110-8635-dbb6e79beafd', // fabian.hasselquist@bambora.com
    '25cf03b3-92da-4009-84ae-380b9c0c0322', // martin.siden@bambora.com
    '91c31ec3-eada-42a6-b417-8a6155a20635', // jonas.valtersson@bambora.com
    '7a44a251-bd24-4c8d-b248-bbdd70f897ec', // love.lundin@bambora.com
    '3c36ea10-de22-4421-89d3-5ae6230878f2', // simon.larsen@bambora.com
    '453a5fe6-91c0-4d18-8e45-feb7dfc8a74a',  // acquiring@backoffice.com
    '19303402-79e9-4db7-9406-b1cafe2a6f47'
]

// ISO 3166 (2 chars) - https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
export const ALLOWED_MERCHANT_COUNTRIES_FOR_LOAN_2 = ['FI', 'SE', 'NO', 'DK']

export const ALLOWED_USERS_TO_ALWAYS_ACCESS_PORTAL = [
    '18aae0bd-4d43-45e7-852a-fcc782bedfde', // mats.wahlberg@bambora.com
    '6ff60413-7f98-4110-8635-dbb6e79beafd', // fabian.hasselquist@bambora.com
    '25cf03b3-92da-4009-84ae-380b9c0c0322', // martin.siden@bambora.com
    '91c31ec3-eada-42a6-b417-8a6155a20635', // jonas.valtersson@bambora.com
    '7a44a251-bd24-4c8d-b248-bbdd70f897ec', // love.lundin@bambora.com
    '3c36ea10-de22-4421-89d3-5ae6230878f2'  // simon.larsen@bambora.com
]

/* eslint-disable */
export const cspPolicy = {
    'default-src': ["'self'", "https://*.bambora.com"],
    'worker-src': 'blob:',
    'style-src': ["'self'", "https://*.bambora.com", "'unsafe-inline'", "https://*.googleapis.com", "https://*.force.com", "https://bambora.my.salesforce-sites.com/chat/*"],
    'img-src': ["'self'", "data:", "https://*.bambora.com", "https://*.amazonaws.com"],
    'font-src': ["'self'", "htps://*.bambora.com", "https://sfdcstatic.com", "https://*.sfdcstatic.com", "https://*.gstatic.com", "data:"],
    'script-src': ["'self'", "'unsafe-inline'", "'unsafe-eval'", "https://*.bambora.com", "https://*.force.com", "https://*.salesforceliveagent.com", "https://*.salesforce.com", "https://bambora.my.salesforce-sites.com/chat/*"],
    'connect-src': [
        "'self'",
        "https://cognito-idp.eu-west-1.amazonaws.com",
        "https://*.bambora.com",
        "https://*.mixpanel.com",
        "https://*.force.com",
        "https://*.froda.se",
        "https://api.froda-dev.se",
        "https://*.sentry.io",
        "ws://localhost:8081/ws",
        "https://rum.browser-intake-datadoghq.com",
        "https://browser-intake-datadoghq.com",
        "https://browser-intake-datadoghq.com/api/v2/rum"
    ],
    'frame-src': ["https://*.bambora.com", "https://*.force.com", "http://*.froda.se", "https://*.froda.se", "https://froda.se", "http://froda.se", "https://api.froda-dev.se", "https://frodadownload.blob.core.windows.net/", "https://*.vimeo.com", "https://www.surveyhero.com/e/wvhphsp9"]
}
/* eslint-enable */
