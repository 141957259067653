import { localStorageClear } from './localstorageHelpers'
import { signOut } from '@frontrunners/wl-cognito-login'


export function redirectToLogin() {
    /* eslint-disable no-unused-vars */
    return (dispatch, getState) => {
        window.location.replace('/login')
    }

}

export function redirectToLogout(redirectUrl) {
    return (dispatch, getState) => {
        const UserPoolId = getState().getIn(['config', 'cognitoPoolId'])
        const ClientId = getState().getIn(['config', 'cognitoClientId'])

        signOut({UserPoolId, ClientId})
        localStorageClear()

        const url = redirectUrl ? `/login?redirectUrl=${redirectUrl}` : '/'
        window.location.replace(url)
    }
}
