import * as t from './actionTypes'
import { useHistory } from 'react-router-dom'
import { runRequest, fetchJson, merchantError } from '../common/requestActionHelper'
import * as localstorageHelpers from '../common/localstorageHelpers'
import { putConsent } from '../loans/actions'
import { showNoMerchantsErrorMessage } from '../errors/actions'
import { ROLES, STATES, ONLINE_SYSTEMS, MIXPANEL_OPT_OUT_USER, ELEVATED_ROLE_PRIORITY } from './constants'
import { MERCHANTS_BASE, USER_BASE } from '../common/constants'
import { updateMixpanelProfile } from '../integrations/mixpanel/mixpanel'
import mixpanel from 'mixpanel-browser'

import { injectDynamicReducers } from '../store'

import { IMPERSONATE_USER_ID } from '../config/constants'
import { localStorageGetItem } from '../common/localstorageHelpers'
import { determineCurrentLocalization } from '../localization/helpers'
import { ALLOWED_USERS_TO_ALWAYS_ACCESS_PORTAL } from '../constants'

export const loadMerchantData = (merchantId) => {
    return (dispatch, getState) => {
        const merchants = getState().get('merchant').get('merchants').map(merchant => merchant.id)

        if (!merchants.includes(merchantId)) useHistory().push('/')

        const merchantAndUserPromises = fetchMerchantAndUserPair(dispatch, getState, merchantId, merchants)
        Promise.all(merchantAndUserPromises).then(merchantAndUserPair => {
            // update redux state and update mixpanel profile
            handleMerchantAndUserPairs(dispatch, getState, merchantAndUserPair, mixpanel)
        }).catch((e) => {
            console.log('ERR SET MERCHANTS', e)
        })
    }
}

export const getMerchantsForUser = (userID, store) => {
    const customResponseHandler = getCustomReponseHandler(merchantError, [400, 403, 404, 500, 501, 502, 503, 504])
    const state = store.getState()
    const config = state.get('config')
    const client_id = config.get('clientId')

    return runRequest({
        startAction: getMerchantsForUserStart,
        successAction: (json) => getMerchantsForUserSuccess(json, store),
        errorAction: getMerchantsForUserError,
        requestObj: {
            url: `<apihost>/v1/users/${userID}/merchants/basic?client_id=${client_id}`,
            ignoreGlobalMerchant: true
        },
        customResponseHandler
    })
}

const getMerchantsForUserStart = () => {
    return {
        type: t.GET_MERCHANTS_FOR_USER_START,
        payload: {
            isFetching: true
        }
    }
}


const getMerchantsForUserError = () => {
    return {
        type: t.GET_MERCHANTS_FOR_USER_ERROR,
        payload: {
            isFetching: false
        }
    }
}

const getMerchantsForUserSuccess = (json, store) => {
    const merchants = json && json.data && json.data.map(merchant => {
        merchant.state = mapMerchantState(merchant)
        return merchant
    })
    if (json && (!merchants || merchants.length === 0)) {
        // it is probably a supportal user not impersonating an end user
        return showNoMerchantsErrorMessage()
    }
    const merchantIds = merchants.map(merchant => merchant.id)
    injectDynamicReducers(store, merchantIds)

    return {
        type: t.GET_MERCHANTS_FOR_USER,
        payload: {
            merchants
        }
    }
}

function fetchMerchantAndUserPair(dispatch, getState, merchantId, allowedMerchants) {
    // fetch merchant data and merchant user data for a merchant
    return [
        fetchMerchantData(dispatch, getState, merchantId, allowedMerchants),
        fetchMerchantUserData(dispatch, getState, merchantId)
    ]
}

function handleMerchantAndUserPairs(dispatch, getState, merchantAndUserPair, mixpanel) {
    // set redux state
    const merchant = toReduxMerchant(merchantAndUserPair, mixpanel, dispatch, getState)
    dispatch(updateMerchantsList(merchant))

    setLoanConsent(dispatch, merchant)

    // also update mixpanel profile
    const [orginalMerchant, users] = merchantAndUserPair
    const user = getUser(getState, users)

    updateMixpanelProfile(mixpanel, user, orginalMerchant)

    // Update locale on user if needed
    const locale = determineCurrentLocalization()
    if(user && user.locale !== locale) {
        dispatch(updateUser({id: user.id, locale: locale}))
    }
}

function setLoanConsent(dispatch, merchant) {
    const currentUserRole = merchant && merchant.loggedInUserRole
    const roleHasPermission = [ROLES.ADMIN, ROLES.OWNER].includes(currentUserRole)
    const userHasPermission = merchant && roleHasPermission && !merchant.loggedInUserIsSupport
    const merchantIsLoanEligible = merchant.extra_data && merchant.extra_data.loan_eligible

    if (userHasPermission && merchantIsLoanEligible) {
        dispatch(putConsent(merchant.id))
    }
}

export function toReduxMerchant(merchantAndUserPair, mixpanel, dispatch, getState) {
    const [merchant, users] = merchantAndUserPair
    // inject merchant state (aquiring, trial, prod etc.)
    merchant.state = mapMerchantState(merchant)

    const user = getUser(getState, users)

    if (user) {
        merchant.loggedInUserEmail = user.email
        merchant.loggedInUserIsSupport = userIsSupport(user, merchant)
    } else {
        merchant.loggedInUserIsSupport = true
        mixpanel.opt_out_tracking() // Opt out of tracking if you're impersonating
    }

    if (merchant.loggedInUserIsSupport) {
        const impersonateUserId = getImpersonateUserSubId(getState)
        const impersonateUser = users.find(u => u.id === impersonateUserId)
        merchant.loggedInUserRole = getUserRole(impersonateUser, merchant)
    }
    else {
        // inject current users state for a merchant
        merchant.loggedInUserRole = getUserRole(user, merchant)
    }



    if (user && user.id && MIXPANEL_OPT_OUT_USER.includes(user.id)) {
        mixpanel.opt_out_tracking()
    }
    return merchant
}

const getImpersonateUserSubId = (getState) => {
    const path = getState().getIn(['config', 'deepLinkPath'])
    let impersonateUserId = path[IMPERSONATE_USER_ID]
    if (!impersonateUserId) impersonateUserId = localStorageGetItem(IMPERSONATE_USER_ID)

    return impersonateUserId
}
export function userIsSupport(user, merchant) {
    return !findMerchantRole(user, merchant.id)
}

export const getCustomReponseHandler = (handler, errorCode) => {
    return errorCode.reduce((obj, item) => ({ ...obj, [item]: handler }), {})
}

function fetchMerchantData(dispatch, getState, merchantId, allowedMerchants) {
    const customReponseHandler = getCustomReponseHandler(merchantError, [400, 403, 404, 500, 501, 502, 503, 504])
    return fetchJson(
        dispatch,
        getState,
        {
            url: `${MERCHANTS_BASE}/${merchantId}/details`,
            merchantId,
            allowedMerchants
        },
        customReponseHandler
    ).then(merchantResponse => {
        const merchantData = mapMerchantSystems(merchantResponse.data)
        return Promise.resolve(merchantData)
    }).catch(e => {
        console.log('Error when fetching merchant object: ', e)
    })
}

export function mapMerchantSystems(merchantData) {
    merchantData.isOnlineMerchant = false
    if (merchantData.system_set.find((x) => ONLINE_SYSTEMS.includes(x.system))) {
        merchantData.isOnlineMerchant = true
    }
    return merchantData
}

function fetchMerchantUserData(dispatch, getState, merchantId) {
    // TODO: make endpoint listing single merchant user by subject/id
    // in the identity store
    return fetchJson(dispatch, getState, {
        url: `${MERCHANTS_BASE}/<merchant>/users`,
        merchantId
    }).then(usersResponse => {
        return Promise.resolve(usersResponse)
    })
}

function getUser(getState, users) {
    const sub = getState().getIn(['user', 'profile', 'sub'])
    return users.find(u => u.id === sub)
}

export function mapMerchantState(merchant) {
    if (!merchant.activation_link && !merchant.prod_merchant_id && !merchant.trial_merchant_id) {
        return STATES.ACQUIRER
    }
    if (merchant.activation_link && !merchant.trial_merchant_id && !merchant.prod_merchant_id) {
        return STATES.TRIAL_NOT_ACTIVATED
    }
    if (merchant.prod_merchant_id) {
        return STATES.TRIAL_ACTIVATED
    }
    if (merchant.trial_merchant_id) {
        return STATES.PROD
    }
    throw ('Could not resolve merchant state.')
}

function findMerchantRole(user, merchantId) {
    return user.merchant_roles.find(x => x.merchant_id === merchantId)
}

export function getElevatedRole(roles) {
    return ELEVATED_ROLE_PRIORITY.find((role) => roles.includes(role))
}

export function getUserRole(user, merchant) {
    if (user) {
        const roleObj = findMerchantRole(user, merchant.id)
        if (roleObj) {
            const role = getElevatedRole(roleObj.roles)
            if (role) {
                return role
            }
        }
    }

    return ROLES.USER
}

export function notPortalEligible(merchant, user) {
    if (user && ALLOWED_USERS_TO_ALWAYS_ACCESS_PORTAL.includes(user.get('sub'))){
        return false
    }
    return merchant.portal_eligible === false
}

function updateMerchantsList(merchant) {
    return {
        type: t.UPDATE_MERCHANTS_LIST,
        payload: {
            merchant
        }
    }
}

export const setMerchant = (merchant) => {
    // persist in localStorage (used by the requestActionHelper for populating the merchants header)
    if (merchant) {
        localstorageHelpers.setGlobalMerchant(merchant.id)
    } else {
        localstorageHelpers.removeGlobalMerchant()
    }

    // and persist in state via reducer
    return {
        type: t.SET_MERCHANT,
        payload: {
            merchant
        }
    }
}

// LIST USERS

export function listMerchantUsers(merchantId) {
    return (dispatch, getState) => { // eslint-disable-line

        const currentUserId = getState().getIn(['user', 'profile', 'sub'])

        return runRequest({
            startAction: listMerchantUsersStart,
            successAction: response => listMerchantUsersSucess(response, currentUserId),
            errorAction: listMerchantUsersError,
            requestObj: { url: `${MERCHANTS_BASE}/<merchant>/users`, merchantId }
        })(dispatch, getState)
    }
}

function listMerchantUsersStart() {
    return {
        type: t.LIST_USERS_START,
        payload: {
            isFetching: true
        }
    }
}

const listMerchantUsersError = () => {
    return {
        type: t.LIST_USERS_ERROR,
        payload: new Error(),
        error: true
    }
}

function listMerchantUsersSucess(users, currentUserId) {
    users = users.map(u => {
        u.role = getElevatedRole(u.merchant_roles[0].roles)
        return u
    })
    // setting currrent user to admin if not found, since the user
    // then has to be a support user

    return {
        type: t.LIST_USERS_SUCCESS,
        payload: {
            users,
            currentUser: users.find(u => u.id === currentUserId) || { role: ROLES.ADMIN },
            isFetching: false
        }
    }
}

// ADD USERS

export const toggleAddUserModal = () => {
    return {
        type: t.TOGGLE_ADD_USER_MODAL
    }
}

export function addUser(merchantId, user) {
    return runRequest({
        startAction: addUserStart,
        successAction: addUserSuccess,
        errorAction: addUserError,
        requestObj: {
            url: `${MERCHANTS_BASE}/<merchant>/users`,
            method: 'POST',
            body: JSON.stringify({ given_name: user.givenName, family_name: user.familyName, email: user.email, role: user.role }),
            merchantId
        }
    })
}


const addUserStart = () => {
    return {
        type: t.ADD_USER_START
    }
}

const addUserError = () => {
    return {
        type: t.ADD_USER_ERROR
    }
}

const addUserSuccess = (user) => {
    user.role = user.merchant_roles[0].roles[0]

    return {
        type: t.ADD_USER_SUCCESS,
        payload: {
            user
        }
    }
}

// CHANGE USERS ROLE

export function setUserRole(merchantId, user, role) {
    return runRequest({
        startAction: () => setUserRoleStart(user, role),
        successAction: (data) => setUserRoleSuccess(data, user, role),
        errorAction: () => setUserRoleError(user, role),
        requestObj: {
            url: `${MERCHANTS_BASE}/<merchant>/users/${user.id}`,
            method: 'PUT',
            body: JSON.stringify({ role: role }),
            merchantId
        }
    })
}


const setUserRoleStart = (user, role) => {
    return {
        type: t.CHANGE_USER_ROLE_START,
        payload: {
            user,
            role
        }
    }
}

const setUserRoleError = (user, role) => {
    return {
        type: t.CHANGE_USER_ROLE_ERROR,
        payload: {
            user,
            role
        }
    }
}

const setUserRoleSuccess = (data, user, role) => {
    return {
        type: t.CHANGE_USER_ROLE_SUCCESS,
        payload: {
            user,
            role
        }
    }
}


// RENAME USER

export const toggleRenameUserModal = () => {
    return {
        type: t.TOGGLE_RENAME_USER_MODAL,
        payload: {}
    }
}

export function updateUser(user) {
    const reqBody = {}

    if (user.givenName) {
        reqBody.givenName = user.givenName
    }

    if (user.familyName) {
        reqBody.familyName = user.familyName
    }

    if (user.locale) {
        reqBody.locale = user.locale
    }

    return runRequest({
        startAction: () => updateUserStart(user),
        successAction: (data) => updateUserSuccess(data, user),
        errorAction: () => updateUserError(user),
        requestObj: {
            url: `${USER_BASE}/${user.id}`,
            method: 'PUT',
            body: JSON.stringify(reqBody)
        }
    })
}

const updateUserStart = (user) => {
    return {
        type: t.UPDATE_USER_START,
        payload: {
            user
        }
    }
}

const updateUserError = (user) => {
    return {
        type: t.UPDATE_USER_ERROR,
        payload: {
            user
        }
    }
}

const updateUserSuccess = (user) => {
    return {
        type: t.UPDATE_USER_SUCCESS,
        payload: {
            user
        }
    }
}


// REMOVE USER

export const toggleRemoveUserModal = (user) => {
    return {
        type: t.TOGGLE_REMOVE_USER_MODAL,
        payload: {
            user
        }
    }
}

export function removeUser(merchantId, user) {
    return runRequest({
        startAction: removeUserStart,
        successAction: () => removeUserSuccess(user),
        errorAction: removeUserError,
        requestObj: {
            url: `${MERCHANTS_BASE}/<merchant>/users/${user.id}`,
            method: 'DELETE',
            merchantId
        }
    })
}


const removeUserStart = () => {
    return {
        type: t.REMOVE_USER_START
    }
}

const removeUserError = () => {
    return {
        type: t.REMOVE_USER_ERROR
    }
}

const removeUserSuccess = (user) => {
    return {
        type: t.REMOVE_USER_SUCCESS,
        payload: {
            user
        }
    }
}

export const setActiveMerchant = (merchantId) => {
    return {
        type: t.SET_ACTIVE_MERCHANT,
        payload: {
            merchantId
        }
    }
}
